import { Component, OnInit, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';

import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Plan } from 'src/app/interfaces';

export interface User {
  email: string;
  firstName: string;
  phone: string;
  acceptPrivacy: boolean;
}

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit {
  env = environment;
  signupForm: FormGroup;
  awaitingResponse = false;

  freePlanDoc: AngularFirestoreDocument<Plan>;
  freePlanObservable: Observable<Plan>;
  freePlan: Plan;

  constructor(
    private fb: FormBuilder,
    public afAuth: AngularFireAuth,
    private router: Router,
    private db: AngularFirestore,
    private snackBar: MatSnackBar,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.signupForm = this.fb.group({
      email: [, Validators.required],
      password: [, [Validators.required]],
      confirmPassword: [, [Validators.required]],
      organisationName: [, Validators.required],
      url: [, Validators.required],
      termsUrl: [, Validators.required],
      // voucherPrefix: [, Validators.required],
      // terms: [, Validators.required],
    });
    if (this.env.name === 'Groene Bon') {
      this.freePlanDoc = this.db.doc<Plan>('plans/GroeneBonFree');
    } else if (this.env.name === 'Lokale Bon') {
      this.freePlanDoc = this.db.doc<Plan>('plans/LokaleBonFree');
    }
    this.freePlanObservable = this.freePlanDoc.valueChanges();
    this.freePlanObservable.subscribe((plan) => {
      this.freePlan = plan;
    });
  }

  async signup() {
    this.awaitingResponse = true;
    const signupData = this.signupForm.value;
    console.log('signupData', signupData);
    console.log('freePlan', this.freePlan);
    if (
      !this.urlCheck(signupData.url, 'url') ||
      !this.urlCheck(signupData.termsUrl, 'terms')
    ) {
      this.awaitingResponse = false;
      return;
    }
    if (signupData.password === signupData.confirmPassword) {
      this.afAuth.auth
        .createUserWithEmailAndPassword(signupData.email, signupData.password)
        .then(async (res) => {
          const townshipId = this.db.createId();
          const userDoc = this.db.doc('users/' + res.user.uid);
          const orgDoc = this.db.doc('township/' + townshipId);
          // const batch = this.db.firestore.batch();
          await userDoc.set(
            {
              email: signupData.email,
              township: townshipId,
              // voucherPrefix: 'lokaleBon',
            },
            { merge: true }
          );
          // batch.set(orgDoc.ref, {
          //   name: signupData.organisationName,
          //   inviteMessage: 'Wil je berichten ontvangen van @Organisatie?',
          //   plan: this.freePlan,
          // }, { merge: true });
          // batch.set(orgDoc.collection('users').doc(res.user.uid).ref, {
          //   email: signupData.email,
          //   userRef: userDoc.ref,
          //   rights: 'owner',
          // });
          console.log('environment', environment);
          const requestUrl = `${environment.functionsUrl}/httpCreateTownship`;
          const plan = this.freePlan;
          plan.id = this.freePlanDoc.ref.id;
          const postData = {
            creatorEmail: signupData.email,
            creatorId: res.user.uid,
            townshipId,
            townshipData: {
              name: signupData.organisationName,
              url: signupData.url,
              termsUrl: signupData.termsUrl,
              plan,
              voucherPrefix: environment.prefix,
              voucherImg: environment.prefixImg,
              primaryColor: '#1a697f',
              accentColor: '#fc9f5b',
              prefix: '',
            },
          };
          console.log('postData', postData);
          this.http.post(requestUrl, postData).subscribe((response) => {
            const result = response['status'] as string;
            this.awaitingResponse = undefined;
            console.log('result', result);
            if (result === 'success') {
              // this.snackBar.open('Je aanmelding is ontvangen.', '', {
              //   duration: 5000,
              // });
              console.log('Successfully signed up!', response);
              this.router.navigate(['/dashboard-township'], {});
            } else {
              this.snackBar.open(
                'Een onbekende fout is opgetreden, neem contact op of probeer het later nog een keer.',
                '',
                {
                  duration: 10000,
                }
              );
            }
          });
          // await batch.commit();
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log('errorCode', errorCode);
          console.log('errorMessage', errorMessage);
          switch (errorCode) {
            case 'auth/email-already-in-use':
              this.snackBar.open(
                'Er is al een account aan dit e-mailadres gekoppeld.',
                '',
                {
                  duration: 5000,
                }
              );
              break;
            case 'auth/weak-password':
              this.snackBar.open(
                'Je wachtwoord moet minimaal 6 karakters zijn.',
                '',
                {
                  duration: 5000,
                }
              );
              break;
            default:
              this.snackBar.open('Er is een onbekende fout opgetreden.', '', {
                duration: 5000,
              });
          }
          this.awaitingResponse = false;
          // ...
        });
    } else {
      this.snackBar.open('Je wachtwoorden komen niet overeen', '', {
        duration: 5000,
      });
      this.awaitingResponse = false;
      console.log('Confirm password is not correct');
    }
  }

  urlCheck(saveObj, controller) {
    let invalidUrl = false;

    const expression = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    const regex = new RegExp(expression);

    if (saveObj.match(regex)) {
      // console.log('valid URL', saveObj);
    } else {
      invalidUrl = true;
    }
    if (invalidUrl) {
      if (controller === 'terms') {
        this.signupForm.controls.termsUrl.setErrors({
          notMatched: true,
        });
      } else {
        this.signupForm.controls.url.setErrors({
          notMatched: true,
        });
      }
      return false;
    } else {
      return true;
    }
  }
}
