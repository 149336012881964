import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material';
import { Township, VoucherGroup } from 'src/app/interfaces';
import { UpgradeDialogComponent } from 'src/app/nav-bar/dialogs/upgrade-dialog/upgrade-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class FuncsService {
  constructor(public db: AngularFirestore, public dialog: MatDialog) {}

  async showUpgradeDialogIfOverLimit() {
    const overLimit = await this.checkIfOverLimit('total');
    if (overLimit) {
      const townshipId = localStorage.getItem('township');
      const townshipRef = this.db.doc(`/township/${townshipId}`);
      const township = (await townshipRef.ref.get()).data() as Township;
      return this.dialog.open(UpgradeDialogComponent, {
        width: '500px',
        data: { township },
      });
    }
  }

  async checkIfOverLimit(type: 'total' | 'activate' | 'claim') {
    const townshipId = localStorage.getItem('township');
    const townshipRef = this.db.doc(`/township/${townshipId}`);
    const township = (await townshipRef.ref.get()).data() as Township;
    // console.log('township', township);
    if (township.plan.maxVouchers || township.vouchersBought) {
      const voucherGroupsRef = this.db.collection(
        `/township/${townshipId}/voucherGroups`
      );
      const voucherGroups = (await voucherGroupsRef.ref.get()).docs;
      // console.log('voucherGroups', voucherGroups);
      let voucherCount = 0;
      voucherGroups.forEach((voucherGroupDoc) => {
        const voucherGroup = voucherGroupDoc.data() as VoucherGroup;
        if (type === 'total' && voucherGroup.totalVouchers) {
          voucherCount = voucherCount + voucherGroup.totalVouchers;
        } else if (type === 'activate' && voucherGroup.activatedVouchers) {
          voucherCount = voucherCount + voucherGroup.activatedVouchers;
        } else if (type === 'claim' && voucherGroup.claimedVouchers) {
          voucherCount = voucherCount + voucherGroup.claimedVouchers;
        }
      });
      // console.log('voucherCount', voucherCount);
      // console.log('township.plan.maxVouchers', township.plan.maxVouchers);
      // re
      if (
        (township.vouchersBought && voucherCount >= township.vouchersBought) ||
        (township.plan.maxVouchers && voucherCount >= township.plan.maxVouchers)
      ) {
        return true;
      }
    }

    return false;
  }
}
