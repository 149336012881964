import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { User, TownshipUser, Township } from '../interfaces';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material';
import { environment } from '../../environments/environment';
import { ManageVoucherComponent } from '../voucher-management/manage-voucher/manage-voucher.component';

@Component({
  selector: 'app-dashboard-township',
  templateUrl: './dashboard-township.component.html',
  styleUrls: ['./dashboard-township.component.scss'],
})
export class DashboardTownshipComponent implements OnInit {
  env = environment;
  userId: Observable<string>;
  userDoc: AngularFirestoreDocument<User>;
  user: Observable<User>;
  townshipDoc: AngularFirestoreDocument<Township>;
  township: Observable<Township>;
  townshipUserDoc: AngularFirestoreDocument<TownshipUser>;
  townshipUser: Observable<TownshipUser>;

  public chartScheme = { domain: ['#b1e4c8', '#7ad0a1', '#5ac288', '#46b473'] };

  constructor(
    public db: AngularFirestore,
    public afAuth: AngularFireAuth,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.afAuth.user.forEach((user) => {
      console.log('user', user);
      this.userDoc = this.db.doc<User>('users/' + user.uid);
      this.user = this.userDoc.valueChanges();
      this.user.forEach((userDoc) => {
        localStorage.setItem('township', userDoc.township);
        if (!userDoc.organisation) {
          console.log('township', userDoc.township);
          this.townshipDoc = this.db.doc<Township>(
            'township/' + userDoc.township
          );
          this.townshipUserDoc = this.db.doc<TownshipUser>(
            'township/' + userDoc.township + '/users/' + user.uid
          );
          this.township = this.townshipDoc.valueChanges();
          this.township.subscribe((township) => {
            // if (township.voucherStatistics) {
            //   this.voucherChartData = [[
            //     township.voucherStatistics.available, township.voucherStatistics.active,
            //     township.voucherStatistics.claimed, township.voucherStatistics.paid
            //   ]];
            //   this.orgChartData = [[
            //     township.organisationStatistics.claimed, township.voucherStatistics.paid
            //   ]];
            // }
            console.log('township', township);
          });
        }
      });
    });
  }

  openEditVoucherDialog(type: string) {
    this.dialog.open(ManageVoucherComponent, {
      width: '373px',
      data: { type },
    });
  }
}
