import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Organisation, Voucher, PaymentReference } from 'src/app/interfaces';
import { CheckVoucherComponent } from './check-voucher/check-voucher.component';
import { SubmitInvoiceComponent } from './submit-invoice/submit-invoice.component';
import { environment } from '../../../environments/environment';
export interface DialogData {
  organisation: Organisation;
  paymentReference: string;
}

export interface ApiResponse {
  amountOfPages: number;
  pageIndex: number;
  totalPayoutValue: number;
  totalVoucherCount: number;
  currentPage: Voucher[];
}

@Component({
  selector: 'app-check-vouchers',
  templateUrl: './check-vouchers.component.html',
  styleUrls: ['./check-vouchers.component.scss'],
})
export class CheckVouchersComponent implements OnInit {
  env = environment;
  voucherCollection: AngularFirestoreCollection<Voucher>;
  public $vouchers: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  vouchersRef: string;

  // voucherData will contains the document items get from collection
  vouchers: Voucher[] = [];

  paymentReferencesCollection: AngularFirestoreCollection<PaymentReference>;
  paymentReferences: PaymentReference[] = [];
  // maintain the count of clicks on Next Prev button
  loading = false;
  awaitingInvoiceResponse = false;
  pageIndex: number;
  amountOfPages: number;
  totalPayoutValue: number;
  totalVoucherCount: number;
  filterGroup: FormGroup;
  // two buttons will be needed by which next data or prev data will be loaded
  // disable next and prev buttons

  constructor(
    public db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    public http: HttpClient
  ) {
    this.filterGroup = new FormGroup({
      period: new FormControl(),
      paymentReference: new FormControl(''),
      search: new FormControl(),
    });
  }

  ngOnInit() {
    this.vouchersRef =
      '/township/' + localStorage.getItem('township') + '/vouchers/';
    console.log('organisation', this.data.organisation);
    this.paymentReferencesCollection = this.db.collection(
      `township/${localStorage.getItem('township')}/organisations/${
        this.data.organisation.id
      }/paymentReferences`
    );
    this.paymentReferencesCollection.valueChanges().subscribe((references) => {
      console.log('references', references);
      this.paymentReferences = references;
    });
    this.filterGroup.valueChanges.subscribe((filters) => {
      console.log(filters);
      this.getPage();
    });
  }
  async getPage(index?) {
    const reqBody: any = {
      townshipId: localStorage.getItem('township'),
      organisationId: this.data.organisation.id,
      endDate: this.filterGroup.controls.period.value,
      startDate: new Date('2019-01-01'),
    };
    if (index) {
      reqBody.pageIndex = index;
    }
    if (this.filterGroup.controls.paymentReference.value) {
      reqBody.paymentReference = this.filterGroup.controls.paymentReference.value;
    }
    if (this.filterGroup.controls.search.value) {
      reqBody.search = this.filterGroup.controls.search.value;
    }
    if (!reqBody.endDate) {
      return;
    }
    const request = this.http.post(
      `${this.env.functionsUrl}/httpCheckVouchers`,
      reqBody
    );
    this.loading = true;
    console.log('reqBody', reqBody);

    request.subscribe((res: ApiResponse) => {
      try {
        console.log(res);
        this.vouchers = res.currentPage;
        this.totalPayoutValue = res.totalPayoutValue;
        this.totalVoucherCount = res.totalVoucherCount;
        this.pageIndex = res.pageIndex;
        this.amountOfPages = res.amountOfPages;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        // TODO: show user an error;
      }
    });
  }
  async checkVoucher(voucher) {
    console.log('voucher', voucher);

    const dialogRef = this.dialog.open(CheckVoucherComponent, {
      width: '373px',
      data: { voucherNumber: voucher.number },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      console.log('The dialog was closed');
      if (result) {
        // batches.forEach(async (batch) => {
        //   console.log({ batch });
        //   await batch.commit();
        // });
        const index = this.vouchers.findIndex(
          (x) => x.number === voucher.number
        );
        if (index !== -1) {
          this.vouchers[index] = result;
          this.$vouchers.next(this.vouchers);
        }
        // this.snackBar.open('De bonnen zijn als betaald gemarkeerd.', 'X', {
        //   duration: 5000,
        // });
      }
    });
  }
  async submitInvoice() {
    if (this.awaitingInvoiceResponse) {
      return;
    }
    const dialogRef = this.dialog.open(SubmitInvoiceComponent, {
      width: '373px',
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.awaitingInvoiceResponse = true;
        console.log('result', result);
        // TODO: save result as paymentReference in all vouchers in selection
        // https://us-central1-wemaron-vouchers.cloudfunctions.net/httpSetPaymentreference
        const reqBody: any = {
          townshipId: localStorage.getItem('township'),
          organisationId: this.data.organisation.id,
          endDate: this.filterGroup.controls.period.value,
          startDate: new Date('2019-01-01'),
          newPaymentReference: result,
        };
        if (this.filterGroup.controls.paymentReference.value) {
          reqBody.paymentReference = this.filterGroup.controls.paymentReference.value;
        }
        if (this.filterGroup.controls.search.value) {
          reqBody.search = this.filterGroup.controls.search.value;
        }
        console.log('reqBody', reqBody);
        const request = this.http.post(
          `${this.env.functionsUrl}/httpSetPaymentreference`,
          reqBody
        );
        request.subscribe((res: any) => {
          try {
            console.log('res', res);
            this.snackBar.open('De rekening is ingediend.', 'X', {
              duration: 5000,
            });
            this.awaitingInvoiceResponse = false;
          } catch (err) {
            console.log('err', err);
            this.snackBar.open(
              'Er is iets misgegaan bij het indienen van de rekening, probeer het later nog een keer.',
              'X',
              {
                duration: 5000,
              }
            );
            this.awaitingInvoiceResponse = false;
          }
        });
      }
    });
  }
}
