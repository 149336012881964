import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';
import { environment } from '../environments/environment';
import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
registerLocaleData(localeNl);

import { HttpClientModule } from '@angular/common/http';
import {
  MatButtonModule,
  MatIconModule,
  MatListModule,
  MatSidenavModule,
  MatToolbarModule,
  MatRippleModule,
  MatTableModule,
  MatDialogModule,
  MatSnackBarModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatSlideToggleModule,
  MatSelectModule,
  MatAutocompleteModule,
  MatTooltipModule,
  DateAdapter,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatChipsModule,
  MatStepperModule,
  MatSliderModule,
} from '@angular/material';
import { MatMenuModule } from '@angular/material/menu';
import {
  MatFormFieldModule,
  MatFormFieldDefaultOptions,
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
} from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatBadgeModule } from '@angular/material/badge';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { LoginComponent } from './auth/login/login.component';
import { RegistrationComponent } from './auth/registration/registration.component';
import { InlineSVGModule } from 'ng-inline-svg';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireAuthGuardModule } from '@angular/fire/auth-guard';
import { CustomDateAdapter } from './custom-date-adapter';

import { AvatarModule } from 'ngx-avatar';
import { NgxCsvParserModule } from 'ngx-csv-parser';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {
  CalendarModule,
  DateAdapter as AngularCalendarDateAdapter,
} from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { AgmCoreModule } from '@agm/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ColorPickerModule } from 'ngx-color-picker';

import { ConstantsService } from './common/services/constants.service';
import { DashboardTownshipComponent } from './dashboard-township/dashboard-township.component';
import { VoucherManagementComponent } from './voucher-management/voucher-management.component';
import { OrganisationManagementComponent } from './organisation-management/organisation-management.component';
import { VoucherGroupDetailComponent } from './voucher-management/voucher-group-detail/voucher-group-detail.component';
import { VoucherGroupEditComponent } from './voucher-management/voucher-group-edit/voucher-group-edit.component';
import { EditOrganisationComponent } from './organisation-management/edit-organisation/edit-organisation.component';
import { MarkAsPaidComponent } from './organisation-management/mark-as-paid/mark-as-paid.component';
import { SettingsComponent } from './settings/settings.component';
import { SendVoucherComponent } from './voucher-management/send-voucher/send-voucher.component';
import { ManageVoucherComponent } from './voucher-management/manage-voucher/manage-voucher.component';
import { CheckVouchersComponent } from './organisation-management/check-vouchers/check-vouchers.component';
import { CheckVoucherComponent } from './organisation-management/check-vouchers/check-voucher/check-voucher.component';
import { SubmitInvoiceComponent } from './organisation-management/check-vouchers/submit-invoice/submit-invoice.component';
import { InputPaymentreferenceComponent } from './organisation-management/input-paymentreference/input-paymentreference.component';
import { TagsComponent } from './tags/tags.component';
import { EditTagComponent } from './tags/edit-tag/edit-tag.component';
import { UpgradeDialogComponent } from './nav-bar/dialogs/upgrade-dialog/upgrade-dialog.component';
import { ContinueComponent } from './settings/dialog/continue-dialog/continue.component';
import { ConfirmationComponent } from './voucher-management/dialogs/confirmation-code/confirmation-code.component';
import { GenerateVouchersComponent } from './voucher-management/dialogs/generate-vouchers/generate-vouchers.component';
import { PaymentComponent } from './payment/payment.component';
import { PinComponent } from './settings/dialog/pin/pin.component';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { DiscountComponent } from './organisation-management/dialog/discount/discount.component';

const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline',
};

@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    LoginComponent,
    RegistrationComponent,
    DashboardTownshipComponent,
    SettingsComponent,
    VoucherManagementComponent,
    VoucherGroupDetailComponent,
    VoucherGroupEditComponent,
    OrganisationManagementComponent,
    EditOrganisationComponent,
    MarkAsPaidComponent,
    SendVoucherComponent,
    ManageVoucherComponent,
    CheckVouchersComponent,
    CheckVoucherComponent,
    SubmitInvoiceComponent,
    InputPaymentreferenceComponent,
    TagsComponent,
    EditTagComponent,
    UpgradeDialogComponent,
    ContinueComponent,
    ConfirmationComponent,
    GenerateVouchersComponent,
    PaymentComponent,
    PinComponent,
    DiscountComponent,
  ],
  imports: [
    AppRoutingModule,
    AvatarModule,
    NgxCsvParserModule,
    CalendarModule.forRoot({
      provide: AngularCalendarDateAdapter,
      useFactory: adapterFactory,
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBa8IaoNVTApq_VIRtKq45Kl5RMhf_fcIc',
      libraries: ['places'],
    }),
    InlineSVGModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFirestoreModule,
    AngularFireAuthGuardModule,
    AngularFireFunctionsModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatStepperModule,
    MatSidenavModule,
    MatToolbarModule,
    MatTooltipModule,
    MatMenuModule,
    MatSliderModule,
    MatRippleModule,
    MatCardModule,
    MatCheckboxModule,
    MatBadgeModule,
    MatChipsModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatCardModule,
    MatGridListModule,
    MatProgressSpinnerModule,
    FlexLayoutModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    NgxMatSelectSearchModule,
    MatTableModule,
    MatSnackBarModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatAutocompleteModule,
    NgxChartsModule,
    ColorPickerModule,
    MatRadioModule,
  ],
  entryComponents: [
    LoginComponent,
    RegistrationComponent,
    DashboardTownshipComponent,
    SettingsComponent,
    VoucherManagementComponent,
    VoucherGroupDetailComponent,
    VoucherGroupEditComponent,
    OrganisationManagementComponent,
    EditOrganisationComponent,
    MarkAsPaidComponent,
    SendVoucherComponent,
    ManageVoucherComponent,
    CheckVouchersComponent,
    CheckVoucherComponent,
    SubmitInvoiceComponent,
    InputPaymentreferenceComponent,
    TagsComponent,
    EditTagComponent,
    UpgradeDialogComponent,
    ContinueComponent,
    ConfirmationComponent,
    GenerateVouchersComponent,
    PaymentComponent,
    PinComponent,
    DiscountComponent,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'nl' },
    ConstantsService,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: appearance,
    },
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
