import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MatSnackBar,
  MAT_DIALOG_DATA,
} from '@angular/material';
import { firestore } from 'firebase';
import { Tag } from 'src/app/interfaces';

export interface DialogData {
  tag: Tag;
}

@Component({
  selector: 'app-edit-tag',
  templateUrl: './edit-tag.component.html',
  styleUrls: ['./edit-tag.component.scss'],
})
export class EditTagComponent implements OnInit {
  newTag = false;
  tagId: string;
  tagForm: FormGroup;
  townShipRef = '/township/' + localStorage.getItem('township') + '/';

  constructor(
    public db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditTagComponent>
  ) {}

  ngOnInit() {
    this.tagForm = this.fb.group({
      name: [, Validators.required],
    });
    if (this.data.tag) {
      this.tagId = this.data.tag.id;
      this.tagForm.patchValue(this.data.tag);
    } else {
      this.tagId = this.db.createId();
      this.newTag = true;
    }
  }

  async save() {
    const saveObj = { ...this.tagForm.value } as Tag;
    // Make sure no "null" values are attempting to be saved, also if something is null it will be deleted from the database.
    Object.keys(saveObj).forEach((key) => {
      if (saveObj[key] == null) {
        saveObj[key] = firestore.FieldValue.delete();
      } else if (
        typeof saveObj[key] === 'string' &&
        saveObj[key].length === 0
      ) {
        saveObj[key] = firestore.FieldValue.delete();
      }
    });
    console.log('saveObj', saveObj);
    // Save values to tb :)
    await this.db
      .collection(this.townShipRef + 'tags')
      .doc(this.tagId)
      .set(saveObj, { merge: true });
    this.dialogRef.close();
  }

  getError(name) {
    const field = this.tagForm.get(name);
    if (field.touched || !field.pristine) {
      if (field.hasError('required')) {
        return 'Dit veld moet ingevuld zijn.';
      }
      return '';
    }
  }
}
