import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialog,
  MatSnackBar,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AngularFirestore } from '@angular/fire/firestore';

export interface DialogData {
  townshipId: string;
}

@Component({
  selector: 'app-send-voucher',
  templateUrl: './send-voucher.component.html',
  styleUrls: ['./send-voucher.component.scss'],
})
export class SendVoucherComponent implements OnInit {
  env = environment;
  awaitingResponse = false;
  sendVoucherForm: FormGroup;
  constructor(
    public db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<SendVoucherComponent>,
    private http: HttpClient
  ) {}

  ngOnInit() {
    console.log('data', this.data);
    this.sendVoucherForm = this.fb.group({
      code: [, Validators.required],
      email: [, Validators.required],
    });
  }

  send() {
    if (!this.awaitingResponse) {
      this.awaitingResponse = true;
      this.http
        .post(
          'https://us-central1-wemaron-vouchers.cloudfunctions.net/httpSendVoucherMail',
          {
            email: this.sendVoucherForm.controls.email.value,
            code: this.sendVoucherForm.controls.code.value,
            townshipId: this.data.townshipId,
          }
        )
        .subscribe((res: any) => {
          console.log('res', res);
          try {
            if (res.accepted.length > 0) {
              // mail sent.
              this.snackBar.open('Bon verstuurd', '', {
                duration: 5000,
              });
              return this.dialogRef.close();
            }
          } catch (err) {
            this.awaitingResponse = false;
            //
          }
          this.snackBar.open(
            'Bon versturen mislukt, controleer gegevens en probeer opnieuw.',
            '',
            {
              duration: 5000,
            }
          );
          this.awaitingResponse = false;
        });
    }
  }
}
