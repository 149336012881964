import {
  Component,
  OnInit,
  Inject,
  ElementRef,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { VoucherGroup, Voucher, Tag, Township, Plan } from 'src/app/interfaces';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { firestore } from 'firebase';
import * as XLSX from 'xlsx';
import { MatAutocompleteSelectedEvent, MatSnackBar } from '@angular/material';
import { environment } from '../../../environments/environment';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, take } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { AngularFireStorage } from '@angular/fire/storage';
import { UpgradeDialogComponent } from '../../nav-bar/dialogs/upgrade-dialog/upgrade-dialog.component';
import { GenerateVouchersComponent } from '../dialogs/generate-vouchers/generate-vouchers.component';

import Hashids from 'hashids';

export interface DialogData {
  township: Township;
  voucherGroup: VoucherGroup;
}

@Component({
  selector: 'app-voucher-group-edit',
  templateUrl: './voucher-group-edit.component.html',
  styleUrls: ['./voucher-group-edit.component.scss'],
})
export class VoucherGroupEditComponent implements OnInit, AfterViewInit {
  env = environment;
  saving: boolean;
  @ViewChild('primary', { static: false }) primary: ElementRef;
  @ViewChild('accent', { static: false }) accent: ElementRef;
  newVoucherGroup = false;
  voucherGroupId: string;
  voucherGroupForm: FormGroup;
  townshipId = localStorage.getItem('township');
  townShipRef = '/township/' + this.townshipId + '/';
  // Used for file upload
  storeData: any;
  vouchersFileUploaded: File;
  pdfFileAlreadyUploaded: boolean;
  pdfFileUploaded: File;
  headerImgFileAlreadyUploaded: boolean;
  headerImgFileUploaded: File;
  headerImgUrl: string;
  deleteHeaderImg: boolean;
  worksheet: any;
  // maxVouchersPerGroup: number;
  maxVouchers: number;
  voucherReminderOptions = [
    { val: 0, text: 'Nooit' },
    { val: 7, text: '7 dagen' },
    { val: 14, text: '14 dagen' },
    { val: 30, text: '30 dagen' },
    { val: 60, text: '60 dagen' },
  ];
  // Everything tags
  @ViewChild('tagInput', { static: false })
  tagInput: ElementRef<HTMLInputElement>;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new FormControl();
  allTags: Observable<Tag[]>;
  voucherGroupTags: Observable<Tag[]>;
  private allTagsCollection: AngularFirestoreCollection<Tag>;
  private voucherGroupTagsCollection: AngularFirestoreCollection<Tag>;
  selectedTags = [];
  filteredTags: ReplaySubject<Tag[]> = new ReplaySubject<Tag[]>(1);
  public tagFilterCtrl: FormControl = new FormControl();
  public filteredTagsCtrl: ReplaySubject<Tag[]> = new ReplaySubject<Tag[]>(1);

  shouldGenerate: boolean;
  generateAmount: number;
  generateValue: number;
  hashids: any = new Hashids('', 5);

  planId: string;
  error: string;
  deletePdf: boolean;

  constructor(
    public db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private storage: AngularFireStorage,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<VoucherGroupEditComponent>,
    private snackBar: MatSnackBar,
    private fns: AngularFireFunctions
  ) {}

  rgbToHex(rgb) {
    const rgbArray = rgb.substring(4, rgb.length - 1).split(', ');
    console.log(rgbArray);
    let r = Number(rgbArray[0]).toString(16);
    let g = Number(rgbArray[1]).toString(16);
    let b = Number(rgbArray[2]).toString(16);
    if (r.length === 1) {
      r = '0' + r;
    }
    if (g.length === 1) {
      g = '0' + g;
    }
    if (b.length === 1) {
      b = '0' + b;
    }

    return '#' + r + g + b;

    // return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  }
  ngAfterViewInit() {
    setTimeout(() => {
      if (!this.voucherGroupForm.value.primaryColor) {
        this.voucherGroupForm.controls.primaryColor.setValue(
          this.rgbToHex(getComputedStyle(this.primary.nativeElement).color)
        );
      }
      if (!this.voucherGroupForm.value.accentColor) {
        this.voucherGroupForm.controls.accentColor.setValue(
          this.rgbToHex(getComputedStyle(this.accent.nativeElement).color)
        );
      }
    }, 500);
  }
  ngOnInit() {
    this.voucherGroupForm = this.fb.group({
      name: [, Validators.required],
      slogan: [, Validators.maxLength(40)],
      type: ['request', Validators.required],
      maxVouchers: [, [Validators.min(1), Validators.pattern('^[0-9]*$')]],
      groupLink: [],
      description: [''],
      terms: ['', Validators.required],
      termsURL: [''],
      voucherReminderTime: [0],
      groupContactEmail: [],
      hideVoucherRemainingInfo: [],
      requestName: [],
      requestPhone: [],
      claimInstantly: [],
      noAddressChecks: [],
      forceSpentFullAmount: [],
      dontRequestAddress: [{ value: false, disabled: true }],
      uniqueMailsOnly: [],
      allowUserToSendMail: [],
      validUntilDate: [],
      validUntilTime: [],
      validUntilTimeType: [{ value: 'months', disabled: true }],
      validUntilTimeValue: [{ value: 1, disabled: true }],
      suffix: [''],
      primaryColor: [
        '',
        [Validators.maxLength(7), Validators.minLength(7), Validators.required],
      ],
      accentColor: [
        '',
        [Validators.maxLength(7), Validators.minLength(7), Validators.required],
      ],
    });
    if (this.data.township.plan && this.data.township.plan.maxVouchers) {
      this.maxVouchers = this.data.township.plan.maxVouchers;
      // this.voucherGroupForm.controls.maxVouchers.disable();
      // this.voucherGroupForm.controls.maxVouchers.setValue(
      //   this.maxVouchersPerGroup
      // );
    }
    if (this.data.voucherGroup) {
      console.log(this.data.voucherGroup);
      const patchObj = { ...this.data.voucherGroup } as any;
      this.voucherGroupId = this.data.voucherGroup.id;
      if (this.data.voucherGroup.validUntilDate) {

        patchObj.validUntilDate = this.data.voucherGroup.validUntilDate.toDate();
      }
      if (patchObj.useCustomPdfTemplate) {
        this.pdfFileAlreadyUploaded = true;
      }
      if (patchObj.useHeaderImage) {
        this.headerImgFileAlreadyUploaded = true;
        this.headerImgUrl = this.data.voucherGroup.headerImageUrl;
      }
      if (this.data.voucherGroup.totalVouchers > 0) {
        console.log('disable input forcespentfullamount')
        this.voucherGroupForm.controls.forceSpentFullAmount.disable();
      }
      this.voucherGroupForm.patchValue(patchObj);
      this.validUntilTimeToggleChanged();
      this.noAddressCheckChanged();
    } else {
      this.voucherGroupId = this.db.createId();
      this.newVoucherGroup = true;
    }
    this.voucherGroupTagsCollection = this.db.collection<Tag>(
      this.townShipRef + 'voucherGroups/' + this.voucherGroupId + '/tags'
    );
    this.voucherGroupTags = this.voucherGroupTagsCollection
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as Tag;
            data.id = a.payload.doc.id;
            return data;
          })
        ),
        take(1)
      );
    this.voucherGroupTags.subscribe((value) => {
      value.forEach((tag) => {
        this.selectedTags.push(tag);
      });
      console.log('this.selectedTags', this.selectedTags);
    });
    this.allTagsCollection = this.db.collection<Tag>(`${this.townShipRef}tags`);
    this.allTags = this.allTagsCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as Tag;
          data.id = a.payload.doc.id;
          return data;
        })
      ),
      take(1)
    );
    const combinedFilter = combineLatest(
      this.tagCtrl.valueChanges,
      this.allTags
    );
    combinedFilter
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((values) => {
        console.log('values', values);
        const filteredTags = [];
        const input = values[0];
        const allTags = values[1];
        console.log('allTags', allTags);
        allTags.forEach((tag) => {
          const tagName = tag.name.toLowerCase();
          if (tagName.includes(input)) {
            filteredTags.push(tag);
          }
        });

        console.log('filteredTags', filteredTags);
        this.filteredTags.next(filteredTags);
        // this.filteredUsers.next(this.allUsers.pipe(map(items => items.filter(item => this.checkFilters(item)))));
      });
    this.tagCtrl.setValue('');
  }

  remove(tag: Tag): void {
    const index = this.selectedTags.indexOf(tag);

    if (index >= 0) {
      this.selectedTags.splice(index, 1);
    }
  }

  async selected(event: MatAutocompleteSelectedEvent): Promise<void> {
    // this.selectedTags.push(event.option.viewValue);
    const value = event.option.value as Tag;
    console.log('value', value);

    const foundTag = await this.selectedTags.find((lfTag) => {
      if (lfTag.id === value.id) {
        return lfTag;
      }
    });
    console.log('selectedTags', this.selectedTags);
    console.log('foundTag', foundTag);
    if (!foundTag) {
      this.selectedTags.push(value);
      this.tagInput.nativeElement.value = '';
      this.tagCtrl.setValue('');
    } else {
      this.tagInput.nativeElement.value = '';
      this.tagCtrl.setValue('');
      this.snackBar.open('Dit label hangt al aan deze groep.', 'X', {
        duration: 5000,
      });
    }
  }

  uploadedFile(event, id) {
    const file = event.target.files[0] as File;
    if (!file) {
      return;
    }
    console.log('id', id);
    console.log('file.size', file.size);
    if (id === 'vouchers') {
      this.vouchersFileUploaded = file;
      this.readExcel();
    } else if (id === 'pdf') {
      if (file.size > 5120000) {
        this.snackBar.open('Dit bestand moet onder de 5 MB zijn.', 'X', {
          duration: 5000,
        });
        return;
      }
      this.pdfFileUploaded = file;
    } else if (id === 'headerImg') {
      if (file.size > 2048000) {
        this.snackBar.open('Dit bestand moet onder de 2 MB zijn.', 'X', {
          duration: 5000,
        });
        return;
      } else {
        this.headerImgFileUploaded = file;
      }
    }
  }
  readExcel() {
    const readFile = new FileReader();
    readFile.onload = (e) => {
      this.storeData = readFile.result;
      const data = new Uint8Array(this.storeData);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, { type: 'binary' });
      const firstSheetName = workbook.SheetNames[0];
      this.worksheet = workbook.Sheets[firstSheetName];
    };
    readFile.readAsArrayBuffer(this.vouchersFileUploaded);
    console.log('this.vouchersFileUploaded', this.vouchersFileUploaded);
  }
  colorChanged(color, colorController) {
    colorController.setValue(color);
  }
  validUntilTimeToggleChanged() {
    if (this.voucherGroupForm.value.validUntilTime) {
      this.voucherGroupForm.controls.validUntilTimeType.enable();
      this.voucherGroupForm.controls.validUntilTimeValue.enable();
    } else {
      this.voucherGroupForm.controls.validUntilTimeType.disable();
      this.voucherGroupForm.controls.validUntilTimeValue.disable();
    }
  }
  noAddressCheckChanged() {
    if (this.voucherGroupForm.value.noAddressChecks) {
      this.voucherGroupForm.controls.dontRequestAddress.enable();
    } else {
      this.voucherGroupForm.controls.dontRequestAddress.setValue(false);
      this.voucherGroupForm.controls.dontRequestAddress.disable();
    }
  }

  async save() {
    if (this.saving) {
      return;
    }

    if (this.deletePdf) {
      await this.deleteFile('pdf');
    }

    if (this.deleteHeaderImg) {
      await this.deleteFile('headerImg');
    }
    this.saving = true;

    const tagBatch = this.db.firestore.batch();
    const saveObj = { ...this.voucherGroupForm.value } as VoucherGroup;

    if (saveObj.termsURL) {
      if (!this.urlCheck(saveObj.termsURL, 'terms')) {
        return;
      }
    }
    // if (this.maxVouchersPerGroup) {
    //   saveObj.maxVouchers = this.maxVouchersPerGroup;
    // }
    if (!saveObj.noAddressChecks) {
      saveObj.dontRequestAddress = false;
    }
    console.log('saveObj', saveObj);
    if (!saveObj.validUntilTime) {
      saveObj.validUntilTimeType = firestore.FieldValue.delete();
      saveObj.validUntilTimeValue = firestore.FieldValue.delete();
    }

    // Check tags for changes
    console.log('selectedTags', this.selectedTags);
    await this.voucherGroupTags.forEach(async (tags) => {
      tags.forEach(async (tag) => {
        console.log('voucherGroups tag', tag);
        const foundTag = await this.selectedTags.find((lfTag) => {
          if (lfTag.id === tag.id) {
            return lfTag;
          }
        });
        if (!foundTag) {
          tagBatch.delete(
            this.db.doc(
              `${this.townShipRef}voucherGroups/${this.voucherGroupId}/tags/${tag.id}`
            ).ref
          );
        }
      });
    });
    if (this.selectedTags) {
      await this.selectedTags.forEach((tag) => {
        tagBatch.set(
          this.db.doc(
            `${this.townShipRef}voucherGroups/${this.voucherGroupId}/tags/${tag.id}`
          ).ref,
          tag
        );
      });
    }

    // Make sure no "null" values are attempting to be saved, also if something is null it will be deleted from the database.
    Object.keys(saveObj).forEach((key) => {
      if (saveObj[key] == null) {
        saveObj[key] = firestore.FieldValue.delete();
      }
    });

    if (saveObj.voucherReminderTime === 0) {
      saveObj.voucherReminderTime = firestore.FieldValue.delete();
    }
    // console.log('worksheet', this.worksheet);

    let spreadsheetError;
    const batches = [];
    let totalVouchers = 0;
    if (this.worksheet) {
      const spreadsheet = {};
      Object.keys(this.worksheet).forEach((key) => {
        if (key !== '!ref' && key !== '!margins' && key !== '!autofilter') {
          // console.log('key', key);
          const rowId = key.match(/\d+/g).toString();
          const colId = key.match(/[a-zA-Z]+/g).toString();
          if (!spreadsheet[rowId]) {
            spreadsheet[rowId] = {};
          }
          spreadsheet[rowId][colId] = this.worksheet[key].w;
        }
      });
      // console.log('spreadsheet', spreadsheet);
      const columnNames = spreadsheet[1];
      Object.keys(columnNames).forEach((key) => {
        // console.log('key', key);
        key = key;
        const val = columnNames[key].toLowerCase();
        switch (val) {
          default:
            delete columnNames[key];
            break;
          case 'vouchernummer':
            columnNames[key] = 'number';
            break;
          case 'waarde':
            columnNames[key] = 'value';
            break;
          case 'te betalen waarde':
            columnNames[key] = 'amountToPayOrg';
            break;
          case 'postcode':
            columnNames[key] = 'postal';
            break;
          case 'image url':
            columnNames[key] = 'imageUrl';
            break;
          case 'huisnummer':
            columnNames[key] = 'houseNumber';
            break;
          case 'toevoeging':
            columnNames[key] = 'houseNumberAddition';
            break;
          case 'claimbedrijf':
            columnNames[key] = 'claimOrganisation';
            break;
          case 'claimdatum':
            columnNames[key] = 'claimDate';
            break;
          case 'activatiedatum':
            columnNames[key] = 'activateDate';
            break;
          case 'betaaldatum':
            columnNames[key] = 'paidDate';
            break;
          case 'vervaldatum':
            columnNames[key] = 'validUntilDate';
            break;
          case 'herinnering verstuurd':
            columnNames[key] = 'reminderSend';
            break;
        }
      });
      delete spreadsheet[1];
      // console.log('columnNames', columnNames);
      batches[0] = this.db.firestore.batch();
      let batchIndex = 0;
      Object.keys(spreadsheet).forEach((key) => {
        const rowObj = {} as Voucher;
        Object.keys(spreadsheet[key]).forEach((colKey) => {
          const colName = columnNames[colKey];
          // console.log(spreadsheet[key][colKey]);
          // console.log('colName', colName);
          if (colName && spreadsheet[key][colKey].length !== 0) {
            rowObj[colName] = spreadsheet[key][colKey];
            // now check if current field needs extra processing, overriding rowObj
            if (colName === 'value' || colName === 'amountToPayOrg') {
              rowObj[colName] = Number(spreadsheet[key][colKey]);
            }
            if (
              colName === 'activateDate' ||
              colName === 'claimDate' ||
              colName === 'paidDate' ||
              colName === 'validUntilDate'
            ) {
              const dateArray = spreadsheet[key][colKey].split('-');
              const dateString =
                dateArray[2] + '-' + dateArray[1] + '-' + dateArray[0];
              const timestamp = Date.parse(dateString);
              // if (date == '')
              let date = null;
              if (isNaN(timestamp) === false) {
                date = new Date(timestamp);
                date = new firestore.Timestamp(
                  Math.floor(date.getTime() / 1000),
                  0
                );
              } else {
                console.log('date failure on this voucher:', rowObj);
                spreadsheetError = true;
              }
              rowObj[colName] = date;
            }
            if (colName === 'claimOrganisation') {
              rowObj[colName] = spreadsheet[key][colKey].toLowerCase();
            }
            // set rowObj with value from spreadsheet for ALL fields
          }
        });

        // This line puts undefined dates to null so that it can be queried on with where
        if (!rowObj.activateDate) {
          rowObj.activateDate = null;
        }
        if (!rowObj.claimDate) {
          rowObj.claimDate = null;
        }
        if (!rowObj.paidDate) {
          rowObj.paidDate = null;
        }

        rowObj.voucherGroupId = this.voucherGroupId;
        // console.log('rowObj', rowObj);
        if (!rowObj.reminderSend) {
          rowObj.reminderSend = false;
        }
        // Only upload voucher if it has both of the required values
        if (!isNaN(rowObj.value) && rowObj.number) {
          totalVouchers++;
          const ref = this.db
            .collection(this.townShipRef + 'vouchers/')
            .doc(rowObj['number']);
          if (batches[batchIndex]['_mutations'].length === 500) {
            batchIndex++;
            batches[batchIndex] = this.db.firestore.batch();
          }
          // console.log('rowObj', rowObj);
          batches[batchIndex].set(ref.ref, rowObj, { merge: true });
        } else {
          spreadsheetError = true;
        }
      });
      // console.loag('batches', batches);
      // Object.keys(batches).forEach((key) => {
      //   const currentRow = spreadsheetObj[key];
      // });
    }
    let generatedVoucherBatches = [];
    if (this.shouldGenerate) {
      generatedVoucherBatches = await this.generateVouchers();
    }
    if (!spreadsheetError) {
      // Upload files
      if (this.pdfFileUploaded) {
        const filePath = `${this.townshipId}/pdf-templates/${this.voucherGroupId}.pdf`;
        await this.storage.upload(filePath, this.pdfFileUploaded);
        saveObj.useCustomPdfTemplate = true;
      }
      if (this.headerImgFileUploaded) {
        const filePath = `${this.townshipId}/images/${this.voucherGroupId}`;
        const uploadTask = await this.storage.upload(
          filePath,
          this.headerImgFileUploaded
        );
        saveObj.headerImageUrl = await uploadTask.ref.getDownloadURL();
        saveObj.useHeaderImage = true;
      }

      if (this.deletePdf) {
        saveObj.useCustomPdfTemplate = false;
      }

      if (this.deleteHeaderImg) {
        saveObj.headerImageUrl = '';
        saveObj.useHeaderImage = false;
      }
      await this.db
        .collection(this.townShipRef + 'voucherGroups')
        .doc(this.voucherGroupId)
        .set(saveObj, { merge: true });
      await tagBatch.commit();
      batches.forEach(async (batch) => {
        // console.log(batch);
        await batch.commit();
      });
      generatedVoucherBatches.forEach(async (batch) => {
        // console.log(batch);
        await batch.commit();
      });
      if (totalVouchers > this.maxVouchers - 1) {
        this.dialog.open(UpgradeDialogComponent, {
          width: '500px',
        });
      }

      this.dialogRef.close();
    } else {
      this.snackBar.open(
        'Eén of meer vouchers bevatten niet alle vereisde velden: waarde of vouchernummer.',
        'X',
        {
          duration: 3000,
        }
      );
      this.saving = false;
    }
  }

  openFileInput(id) {
    const element: HTMLElement = document.getElementById(id) as HTMLElement;
    element.click();
  }

  async delete() {
    console.log('voucherGroupForm', this.voucherGroupForm.value);
  }

  openGenerateVouchersDialog() {
    const dialogRef = this.dialog.open(GenerateVouchersComponent, {
      width: '350px',
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      console.log('The dialog was closed', result);
      if (typeof result !== 'undefined') {
        this.shouldGenerate = true;
        this.generateAmount = result[0];
        this.generateValue = result[1];
      }
    });
  }

  async generateVouchers() {
    const batches = [];
    if (this.shouldGenerate) {
      batches[0] = this.db.firestore.batch();
      let batchIndex = 0;
      const prefix = this.data.township.prefix;
      const suffix = this.voucherGroupForm.value.suffix;
      // const dialogRef = this.dialog.open(GenerateVouchersComponent, {
      //   width: '350px',
      // });

      // dialogRef.afterClosed().subscribe(async (result) => {
      const voucherCountFunction = this.fns.httpsCallable(
        'httpGetTotalVouchers'
      );
      const voucherCount = await voucherCountFunction({
        townshipId: this.townshipId,
      }).toPromise();
      console.log('voucherCount', voucherCount);
      // ref.subscribe(async (resultDialog) => {
      let latestNumber = voucherCount;
      latestNumber += 1;
      const generatedVouchers = [];
      for (let i = 0; i < this.generateAmount; i++) {
        const newVoucherName =
          prefix + this.hashids.encodeHex(latestNumber.toString()) + suffix;
        let newVoucher = {} as Voucher;
        newVoucher = {
          activateDate: null,
          claimDate: null,
          number: newVoucherName.toUpperCase(),
          paidDate: null,
          reminderSend: false,
          voucherGroupId: this.voucherGroupId,
          value: this.generateValue,
          receiptValue: null,
          amountToPayOrg: null,
        };
        latestNumber += 1;
        generatedVouchers.push(newVoucher);

        if (batches[batchIndex]['_mutations'].length === 500) {
          batchIndex++;
          batches[batchIndex] = this.db.firestore.batch();
        }
        // console.log('rowObj', rowObj);
        const ref = this.db.doc(
          `township/${this.townshipId}/vouchers/${newVoucher.number}`
        );
        batches[batchIndex].set(ref.ref, newVoucher, { merge: true });
      }
      // });
      console.log('generatedVouchers', generatedVouchers);
      // });
    }
    return batches;
  }
  urlCheck(saveObj: string, controller: string) {
    let invalidUrl = false;

    const expression = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    const regex = new RegExp(expression);

    if (saveObj.match(regex)) {
      console.log('valid URL', saveObj);
    } else {
      invalidUrl = true;
    }

    if (invalidUrl) {
      if (controller === 'terms') {
        this.voucherGroupForm.controls.termsURL.setErrors({
          notMatched: true,
        });
      }
      this.saving = false;
      return false;
    } else {
      return true;
    }
  }
  // dialogRef.afterOpened().subscribe(result => {
  // })

  async openFile(type: string): Promise<any> {
    if (type === 'pdf') {
      let fileUrl = '';
      if (this.pdfFileUploaded || this.pdfFileAlreadyUploaded) {
        fileUrl = `${this.townshipId}/pdf-templates/${this.voucherGroupId}.pdf`;
      } else {
        fileUrl = 'pdf-templates/groene-bon.pdf';
      }
      const file = this.storage.ref(fileUrl).getDownloadURL();
      file.subscribe((url) => {
        console.log('file', url);
        window.open(url);
      });
    } else if (type === 'headerImg') {
      if (this.headerImgFileAlreadyUploaded) {
        window.open(this.headerImgUrl);
      }
    }
    console.log('vouchergroupid', this.voucherGroupId);
  }

  async deleteFile(type: string) {
    let segment;
    let extension = '';
    if (type === 'pdf') {
      segment = 'pdf-templates';
      extension = '.pdf';
    } else if (type === 'headerImg') {
      segment = 'images';

    }
    // const name = type === 'pdf' ? 'PDF' : 'E-mail header';
    const fileUrl = `${this.townshipId}/${segment}/${this.voucherGroupId}${extension}`;
    const file = this.storage.ref(fileUrl).delete();
    await file.toPromise();
    console.log('delted file');
    return 'done';

    // file.subscribe((result) => {
    //   this.snackBar.open(`${name} verwijderd`, 'X', {
    //     duration: 5000
    //   });
    // });
  }

  setDelete(type: string): void {
    const name = type === 'pdf' ? 'PDF' : 'E-mail header';
    if (type === 'pdf') {
      this.deletePdf = true;
    } else if (type === 'headerImg') {
      this.deleteHeaderImg = true;
    }
    this.snackBar.open(`Druk op opslaan om de ${name} definitief te verwijderen`, 'X', {
      duration: 3500
    });
  }
}
