import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { VoucherGroup, Voucher, Tag, Township } from 'src/app/interfaces';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';

// import * as  Hashids  from 'hashids';
import Hashids from 'hashids';

@Component({
  selector: 'app-generate-vouchers',
  templateUrl: './generate-vouchers.component.html',
  styleUrls: ['./generate-vouchers.component.scss'],
})
export class GenerateVouchersComponent implements OnInit {
  generateVouchersForm: FormGroup;
  hashids: any = new Hashids('', 5);

  townshipId = localStorage.getItem('township');
  voucherTemplate: Voucher[] = [];

  parentData: Voucher[] = [];

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<GenerateVouchersComponent>,
    private fb: FormBuilder,
    public db: AngularFirestore,
    public snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.generateVouchersForm = this.fb.group({
      number: [
        ,
        [Validators.min(1), Validators.required, Validators.maxLength(50000)],
      ],
      value: [, [Validators.min(0), Validators.required]],
    });
  }

  close() {
    this.dialogRef.close();
  }

  async generate() {
    if (this.generateVouchersForm.valid) {
      const number = this.generateVouchersForm.value.number;
      const couponValue = this.generateVouchersForm.value.value;

      if (number > 50000) {
        this.showSnackbar('Uw kunt maximaal 50.000 bonnen genereren.', 4000);
      } else {
        // this.showSnackbar('Bonnen worden gegenereerd, even geduld a.u.b.', 4000);

        this.parentData.push(number);
        this.parentData.push(couponValue);
      }
    }
  }

  showSnackbar(message: string, duration: number) {
    this.snackBar.open(message, 'X', {
      duration: duration,
    });
  }
}
