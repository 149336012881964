import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material';
@Component({
  selector: 'app-continue',
  templateUrl: './continue.component.html',
  styleUrls: ['./continue.component.scss']
})
export class ContinueComponent implements OnInit {

  constructor(private router: Router, public dialogRef: MatDialogRef<ContinueComponent>) { }

  ngOnInit() {
  }

  tags() {
    this.dialogRef.close();
    this.router.navigateByUrl('/tags');
  }
}
