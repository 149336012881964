import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-pin',
  templateUrl: './pin.component.html',
  styleUrls: ['./pin.component.scss'],
})
export class PinComponent implements OnInit {
  pinForm: FormGroup;
  checking: boolean;

  constructor(
    public db: AngularFirestore,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<PinComponent>
  ) {}

  ngOnInit() {
    this.pinForm = this.fb.group({
      test: [],
      pin: [, [Validators.required]],
    });
  }
  async submit() {
    if (this.pinForm.valid) {
      this.checking = true;
      const pin = (
        await this.db.collection('admin/').doc('pin').ref.get()
      ).data().code;
      console.log('pin', pin);
      console.log('pinForm.pin', this.pinForm.value.pin);
      if (pin === this.pinForm.value.pin) {
        this.dialogRef.close('success');
      } else {
        this.dialogRef.close('failed');
      }
    } else {
      this.pinForm.markAllAsTouched();
    }
  }
}
