import { Component, OnInit } from '@angular/core';
import {
  AngularFirestoreCollection,
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { VoucherGroup, Voucher, Township, User, Discount } from '../../../interfaces';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map, take, debounceTime } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';


import * as Canvas from 'canvas';
import * as JsBarcode from 'jsbarcode';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-confirmation-code',
  templateUrl: './confirmation-code.component.html',
  styleUrls: ['./confirmation-code.component.scss']
})
export class ConfirmationComponent implements OnInit {

  env = environment;

  townshipRef: string;
  voucherGroupArray: VoucherGroup[];

  voucherGroupForm: FormGroup;
  codeForm: FormGroup;
  orgId: string;
  bardcodeImg: any;
  loading: boolean;

  user: Observable<User>;
  userDoc: AngularFirestoreDocument<User>;

  voucherGroups: Observable<VoucherGroup[]>;
  voucherGroupCollection: AngularFirestoreCollection<VoucherGroup>;
  voucherGroupId: string;
  barcodeLink: string;
  barcodeSource: string;
  existingDiscount: Discount[];

  selected: boolean;

  constructor(public db: AngularFirestore, private fb: FormBuilder, public afAuth: AngularFireAuth,
    private dialogRef: MatDialogRef<ConfirmationComponent>, private _snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.loading = false;
    this.selected = false;
    this.codeForm = this.fb.group({
      code: [, Validators.required],
      voucherGroup: [, Validators.required],
    });
    this.townshipRef = `/township/${localStorage.getItem('township')}/`;

    this.voucherGroupCollection = this.db.collection<VoucherGroup>(this.townshipRef + 'voucherGroups'
    );
    console.log(' this.voucherGroupCollection', this.voucherGroupCollection);
    this.voucherGroups = this.voucherGroupCollection
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as VoucherGroup;
            data.id = a.payload.doc.id;
            return data;
          })
        ),
        take(1)
      );
    this.voucherGroups.subscribe((result) => {
      this.voucherGroupArray = result;
    });
    this.afAuth.user.subscribe((user) => {
      if (user) {
        this.userDoc = this.db.doc<User>('users/' + user.uid);
        this.user = this.userDoc.valueChanges();
        this.user.subscribe((res) => {
          if (res.organisation) {
            this.orgId = res.organisation;
          }
        });
      }
    });

    this.codeForm.controls.code.valueChanges.pipe(debounceTime(500)).subscribe((result) => {
      console.log('result', result);
      console.log(' this.barcodeSource', this.barcodeSource);
      if (result !== this.barcodeSource || result === '') {
        console.log('lets bounce');
        this.barCode();
        this.loading = false;
      }
    });
  }

  retrieve(): void {
    this.codeForm.controls.code.setValue('');
    const data = this.codeForm.value;
    console.log('data', data);
    this.voucherGroupId = data.voucherGroup[0];
    this.selected = false;

    let alreadyExists = false;
    const ref = this.db.collection('township/' + `${localStorage.getItem('township')}/organisations/`).doc(this.orgId);
    ref.get().subscribe((result) => {
      const retData = result.data();
      const existingDiscount = retData.discountCode;
      if (existingDiscount) {
        [...retData.discountCode].forEach((element) => {
          if (element.voucherId && element.voucherId === this.voucherGroupId) {
            if (element.barcode) {
              this.bardcodeImg = element.barcode;
              this.barcodeSource = element.source;
              this.codeForm.controls.code.setValue(element.source);
              alreadyExists = true;
            }
          }
        });
        if (!alreadyExists) {
          existingDiscount[existingDiscount.length] = {
            voucherId: this.voucherGroupId,
            barcode: this.bardcodeImg,
            source: data.code
          };
          if (data.code === '') {
            this.loading = false;
            this.bardcodeImg = null;
          }
        }
      }
      this.existingDiscount = existingDiscount;
    });
  }

  async barCode(): Promise<any> {
    this.loading = true;
    const data = this.codeForm.value;
    console.log('data', data);
    if (data.code === '') {
      this.bardcodeImg = null;
      this.loading = false;
      return;
    }
    this.barcodeSource = data.code;
    this.voucherGroupId = data.voucherGroup[0];
    const ref = this.db.collection('township/' + `${localStorage.getItem('township')}/organisations/`).doc(this.orgId);
    ref.get().subscribe((result) => {
      const retData = result.data();
      if (retData.discountCode) {
        const canvas = Canvas.createCanvas(400, 200);
        JsBarcode(canvas, `${this.barcodeSource}`, {
          marginLeft: 48,
          marginRight: 48,
          marginTop: 10,
          marginBottom: 10,
        });
        this.bardcodeImg = canvas.toDataURL();
        this.loading = false;
      } else {
        const canvas = Canvas.createCanvas(400, 200);
        JsBarcode(canvas, `${this.barcodeSource}`, {
          marginRight: 48,
          marginTop: 10,
          marginBottom: 10,
        });
        this.bardcodeImg = canvas.toDataURL();
        this.loading = false;
      }
    });
    console.log('barcodeimg', this.bardcodeImg);
    this.selected = false;
  }

  save(): void {
    if (this.codeForm.valid) {
      this.selected = true;
      const data = this.codeForm.value;
      // console.log('data', data);
      const voucherGroupId = data.voucherGroup[0];
      const ref = this.db.collection('township/' + `${localStorage.getItem('township')}/organisations/`).doc(this.orgId);
      console.log('this.existingDiscount', this.existingDiscount);
      if (this.existingDiscount) {
        this.existingDiscount.forEach((element: any, index) => {
          if (element.voucherId && voucherGroupId === element.voucherId) {
            if (this.codeForm.value.code !== element.source) {
              console.log('in second if');
              this.existingDiscount[index].source = this.codeForm.value.code;
              this.existingDiscount[index].barcode = this.bardcodeImg;
            }
          }
        });
      } else {
        this.barcodeSource = this.codeForm.value.code;
      }
      if (this.existingDiscount) {
        ref.set({
          discountCode: this.existingDiscount
        }, { merge: true });
      } else {
        ref.set({
          discountCode: [{
            voucherId: this.voucherGroupId,
            barcode: this.bardcodeImg,
            source: this.barcodeSource
          }]
        }, { merge: true });
      }
      // this.dialogRef.close();
      this._snackBar.open('Barcode opgeslagen.', 'x', {
        duration: 5000,
      });
      this.codeForm.controls.code.setValue('');
    }
  }

  delete(): void {
    const data = this.codeForm.value;
    this.existingDiscount.forEach((element: any, i) => {
      if (data.voucherGroup[0] === element.voucherId) {
        this.existingDiscount.splice(i, 1);
      }
    });
    const ref = this.db.collection('township/' + `${localStorage.getItem('township')}/organisations/`).doc(this.orgId);
    ref.set({
      discountCode: this.existingDiscount
    }, { merge: true });
    this.bardcodeImg = null;
    // this.voucherGroupId = null;
    this.codeForm.controls.code.setValue('');
    this._snackBar.open('Barcode verwijderd.', 'x', {
      duration: 5000,
    });
  }

  onFocus(): void {
    this.selected = true;
  }

  onBlur(): void {
    // setTimeout(() => {
    //   this.selected = false;
    // }, 1000);
  }

  close(): void {
    this.dialogRef.close();
  }
}
