import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Plan, PlanGroup, Township } from '../interfaces';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit {
  loading = false;
  env = environment;
  planFormGroup: FormGroup;
  personalDetailsFormGroup: FormGroup;
  confirmFormGroup: FormGroup;

  township: Observable<Township>;
  townshipDoc: AngularFirestoreDocument<Township>;
  plans: Observable<Plan[]>;
  plansCollection: AngularFirestoreCollection<Plan>;
  planGroups: Observable<PlanGroup[]>;
  planGroupsCollection: AngularFirestoreCollection<PlanGroup>;

  public filteredPlans: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  constructor(
    private fb: FormBuilder,
    public db: AngularFirestore,
    private http: HttpClient,
    public snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.planFormGroup = this.fb.group({
      voucherAmount: [0, Validators.required],
      plan: [, Validators.required],
    });
    this.personalDetailsFormGroup = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      postal: [
        '',
        [Validators.required, Validators.minLength(6), Validators.maxLength(7)],
      ],
      city: ['', Validators.required],
      address: ['', Validators.required],
    });
    this.confirmFormGroup = this.fb.group({
      confirm: [, Validators.required],
    });

    this.townshipDoc = this.db.doc<Township>(
      `/township/${localStorage.getItem('township')}`
    );
    this.township = this.townshipDoc.snapshotChanges().pipe(
      map((actions) => {
        const data = actions.payload.data() as Township;
        data.id = actions.payload.id;
        if (!data.vouchersBought) {
          data.vouchersBought = 0;
        }
        this.planFormGroup.controls.voucherAmount.setValue(
          data.vouchersBought + 1000
        );
        return { ...data };
      }),
      take(1)
    );
    this.plansCollection = this.db.collection<Plan>('/plans/');
    this.plans = this.plansCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as Plan;
          data.id = a.payload.doc.id;
          return { ...data };
        })
      ),
      take(1)
    );
    this.planGroupsCollection = this.db.collection<PlanGroup>('/planGroups/');
    this.planGroups = this.planGroupsCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as PlanGroup;
          data.id = a.payload.doc.id;
          return { ...data };
        })
      ),
      take(1)
    );

    const combinedObservable = combineLatest(
      this.township,
      this.plans,
      this.planGroups
    );
    combinedObservable
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((values) => {
        // console.log('values', values);
        const township = values[0] as Township;
        const plans = values[1] as Plan[];
        const planGroups = values[2] as PlanGroup[];
        console.log('township', township);
        console.log('plans', plans);
        console.log('planGroups', planGroups);
        let currentPlanGroup;
        if (this.env.name === 'Groene Bon') {
          currentPlanGroup = 'GroeneBon';
        } else if (this.env.name === 'Lokale Bon') {
          currentPlanGroup = 'LokaleBon';
        }
        const filteredPlans = [];
        // Filter plans to only show current plan group
        plans.forEach((plan: any) => {
          if (
            township.plan.id === plan.id ||
            (township.plan &&
              township.plan.type === 'free' &&
              plan.type === 'free')
          ) {
            plan.current = true;
          }
          if (plan.planGroup === currentPlanGroup) {
            filteredPlans.push(plan);
          }
        });
        // Order plans from free to most expensive
        filteredPlans.sort(function (a: Plan, b: Plan) {
          const aBaseVoucherCount = a.baseVoucherCount ? a.baseVoucherCount : 0;
          const bBaseVoucherCount = b.baseVoucherCount ? b.baseVoucherCount : 0;
          if (aBaseVoucherCount < bBaseVoucherCount) {
            return -1;
          }
          if (aBaseVoucherCount > bBaseVoucherCount) {
            return 1;
          }
          return 0;
        });
        console.log('filteredPlans', filteredPlans);

        this.filteredPlans.next(filteredPlans);
      });
  }
  formatLabel(value: number) {
    if (value >= 1000) {
      return Math.round(value / 1000) + 'k';
    }

    return value;
  }
  sliderChanged(ev, township: Township) {
    if (
      this.planFormGroup.value.plan &&
      ev.value <= township.vouchersBought &&
      this.planFormGroup.value.plan.current
    ) {
      this.planFormGroup.controls.plan.reset();
    }
    if (ev.value < township.vouchersBought) {
      this.planFormGroup.controls.voucherAmount.setValue(
        township.vouchersBought
      );
      this.snackBar.open(
        `Omdat je al ${township.vouchersBought} bonnen hebt gekocht, is dit het minimale wat je kan kiezen.`,
        'X',
        {
          duration: 4000,
        }
      );
    } else if (ev.value < 1000) {
      this.planFormGroup.controls.voucherAmount.setValue(1000);
      this.snackBar.open(`1000 is het minimale wat je kan kiezen.`, 'X', {
        duration: 4000,
      });
    }
  }
  getCurrencyString(number) {
    if (number.toString().indexOf('.') == -1) {
      return `€${number.toString()},-`;
    }
    return `€${number.toFixed(2).replace('.', ',')}`;
  }
  ceil(number) {
    return Math.ceil(number);
  }
  selectPlan(plan, township: Township) {
    console.log(
      township.vouchersBought,
      this.planFormGroup.value.voucherAmount
    );
    if (
      (plan.current &&
        township.vouchersBought >= this.planFormGroup.value.voucherAmount) ||
      (plan.current && plan.type === 'free')
    ) {
      return;
    }
    this.planFormGroup.controls.plan.setValue(plan);
  }
  async pay() {
    this.loading = true;
    const personalDetails = this.personalDetailsFormGroup.value;
    const plan = this.planFormGroup.value.plan;
    // const saveObj = {
    //   personalDetails,
    //   plan,
    // };
    const requestUrl = `${environment.functionsUrl}/molliePay`;
    const postData = {
      townshipId: this.townshipDoc.ref.id,
      plan,
      personalDetails,
    };
    console.log('postData', postData);
    this.http.post(requestUrl, postData).subscribe((res) => {
      const result = res['status'] as string;
      this.loading = undefined;
      console.log('result', result);
    });
    // await this.townshipDoc.update(saveObj);
  }
}
