import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Organisation, PaymentReference } from 'src/app/interfaces';


export interface DialogData {
  organisation: Organisation;
}

@Component({
  selector: 'app-input-paymentreference',
  templateUrl: './input-paymentreference.component.html',
  styleUrls: ['./input-paymentreference.component.scss']
})
export class InputPaymentreferenceComponent implements OnInit {
  paymentReferencesCollection: AngularFirestoreCollection<PaymentReference>;
  paymentReferences: PaymentReference[] = [];
  paymentReference: string;
  constructor(public db: AngularFirestore, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
    this.paymentReferencesCollection = this.db.collection(`township/${localStorage.getItem('township')}/organisations/${this.data.organisation.id}/paymentReferences`, ref => ref.orderBy('lastUpdate', 'desc'));
    this.paymentReferencesCollection.valueChanges().subscribe(references => {
      console.log('references', references);
      this.paymentReferences = references;
    });
  }

}
