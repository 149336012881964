import { Component, OnInit, Inject, ElementRef, Input } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MatSnackBar,
  MAT_DIALOG_DATA,
} from '@angular/material';
import {
  Voucher,
  Organisation,
  User,
  OrganisationTag,
  Tag,
  VoucherGroup,
} from 'src/app/interfaces';
import { firestore } from 'firebase';
import { combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, take } from 'rxjs/operators';
import { ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { element } from 'protractor';
import { environment } from '../../../environments/environment';

export interface DialogData {
  voucherNumber: string;
  type: string;
}

@Component({
  selector: 'app-manage-voucher',
  templateUrl: './manage-voucher.component.html',
  styleUrls: ['./manage-voucher.component.scss'],
})
export class ManageVoucherComponent implements OnInit {
  numberForm: FormGroup;
  voucherForm: FormGroup;
  claimForm: FormGroup;
  activateForm: FormGroup;
  voucherNumber: string;
  voucher: Voucher;
  voucherDoc: AngularFirestoreDocument<Voucher>;
  saving: boolean;

  organisationCollection: AngularFirestoreCollection<Organisation>;
  organisations: Observable<Organisation[]>;
  organisationsArray: Array<Organisation> = [];
  // searchOrganisations: Array<Organisation[]> = [];
  public filteredOrganisations: ReplaySubject<
    Organisation[]
  > = new ReplaySubject<Organisation[]>(1);
  totalOrgsFound: number;
  searchQuery: Subject<string> = new Subject<string>();
  organisationId: string;

  edit: boolean;
  claim: boolean;
  activate: boolean;
  voucherFound: boolean;

  txt: string[] = [];

  userDoc: AngularFirestoreDocument<User>;
  user: Observable<User>;

  townShipRef: string;
  organisationsCollection: AngularFirestoreCollection<Organisation>;

  search: boolean;

  error: boolean;
  errorMessage: string;

  isEntrepreneur: boolean;
  entrepreneurId: string;
  entrepreneurOrg: Array<Organisation>;
  entrepreneurTagsCollection: AngularFirestoreCollection<OrganisationTag>;
  entrepreneurTags: Observable<OrganisationTag[]>;
  entrepreneurTagsObj = {};

  env = environment;

  voucherGroupArray: VoucherGroup[] = [];
  voucherGroupCollection: AngularFirestoreCollection<VoucherGroup>;

  succeedActions: number;

  barcode: string;

  @ViewChild('couponNumber', { static: false }) ref: ElementRef;

  constructor(
    public db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ManageVoucherComponent>,
    public snackBar: MatSnackBar,
    public afAuth: AngularFireAuth,
    public afs: AngularFirestore
  ) { }

  ngOnInit() {
    this.numberForm = this.fb.group({
      number: [, Validators.required],
    });
    this.activateForm = this.fb.group({
      value: [, Validators.required],
      activateDate: [, Validators.required],
      activateOrganisationId: ['none', Validators.required],
      couponNumber: [, Validators.required],
      search: [],
    });
    this.claimForm = this.fb.group({
      claimOrganisationId: ['none', Validators.required],
      claimDate: [, Validators.required],
      receiptValue: [],
      couponNumber: [, Validators.required],
      search: [],
    });
    this.voucherForm = this.fb.group({
      voucherGroupId: [],
      value: [, Validators.required],
      amountToPayOrg: [],
      email: [],
      postal: [, [Validators.minLength(6), Validators.maxLength(6)]],
      houseNumber: [],
      houseNumberAddition: [],
      activateDate: [],
      activateOrganisationId: [],
      claimDate: [],
      claimNumber: [],
      claimOrganisationId: [],
      paidDate: [],
      reminderSend: [],
      couponNumber: [],
      search: [],
    });

    this.edit = false;
    this.voucherFound = false;
    this.claim = false;
    this.activate = false;
    this.search = false;
    this.succeedActions = 0;
    console.log('this.data', this.data);
    if (this.data.voucherNumber) {
      this.getVoucher(this.data.voucherNumber);
    }

    if (this.data.type === 'edit') {
      this.edit = true;
      this.txt.push('Bewerk');
      this.txt.push('bewerken');
      this.txt.push('bewerkt');
    }
    if (this.data.type === 'activate') {
      this.activate = true;
      // this.checked = true;
      this.txt.push('Activeer');
      this.txt.push('activeren');
      this.txt.push('geactiveerd');
    }
    if (this.data.type === 'claim') {
      this.claim = true;
      // this.checked = true;
      this.txt.push('Claim');
      this.txt.push('claimen');
      this.txt.push('geclaimed');
    }

    this.afAuth.user.subscribe((user) => {
      if (user) {
        // console.log('user', user);
        // this.userEmail = user.email;
        this.userDoc = this.afs.doc<User>('users/' + user.uid);
        this.user = this.userDoc.valueChanges();
        this.user.subscribe((res) => {
          console.log('res', res)
          this.townShipRef = '/township/' + res.township + '/';
          if (this.env.env === 'org' || res.organisation) {
            this.isEntrepreneur = true;
            this.entrepreneurId = res.organisation;
            this.activateForm.controls.activateOrganisationId.disable();
            this.activateForm.controls.activateDate.disable();
            this.claimForm.controls.claimOrganisationId.disable();
            this.claimForm.controls.claimDate.disable();
            this.activateForm.patchValue({
              activateOrganisationId: res.organisation,
              activateDate: new Date(),
            });
            this.claimForm.patchValue({
              claimOrganisationId: res.organisation,
              claimDate: new Date(),
            });
            // if (this.claim) {
            //   this.entrepreneurTagsCollection = this.db.collection<OrganisationTag>(
            //     '/township/' +
            //       localStorage.getItem('township') +
            //       '/organisations/' +
            //       this.entrepreneurId +
            //       '/tags',
            //     (ref) => ref.where('claimRights', '==', true)
            //   );
            // }
            // if (this.activate) {
            //   this.entrepreneurTagsCollection = this.db.collection<OrganisationTag>(
            //     '/township/' +
            //       localStorage.getItem('township') +
            //       '/organisations/' +
            //       this.entrepreneurId +
            //       '/tags',
            //     (ref) => ref.where('activateRights', '==', true)
            //   );
            // }
            this.entrepreneurTagsCollection = this.db.collection<OrganisationTag>(
              '/township/' +
              localStorage.getItem('township') +
              '/organisations/' +
              this.entrepreneurId +
              '/tags',
              (ref) => {
                let query: any = ref;
                if (this.claim) {
                  query = query.where('claimRights', '==', true);
                }
                if (this.activate) {
                  query = query.where('activateRights', '==', true);
                }
                return query;
              }
            );
            this.entrepreneurTags = this.entrepreneurTagsCollection
              .snapshotChanges()
              .pipe(
                map((actions) =>
                  actions.map((a) => {
                    const data = a.payload.doc.data() as OrganisationTag;
                    data.id = a.payload.doc.id;
                    return { ...data };
                  })
                ),
                take(1)
              );
            this.entrepreneurTags.subscribe((entrepreneurTags) => {
              console.log('entrepreneurTags', entrepreneurTags);
              entrepreneurTags.forEach((tag) => {
                this.entrepreneurTagsObj[tag.id] = tag;
              });
            });
          }
          // console.log('res',  res);
        });
      }
    });

    this.organisationsCollection = this.db.collection<Organisation>(
      '/township/' + localStorage.getItem('township') + '/organisations',
      (ref) => ref.orderBy('name', 'asc')
    );
    this.organisations = this.organisationsCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as Organisation;
          data.id = a.payload.doc.id;
          if (!data.claimedVouchers) {
            data.claimedVouchers = 0;
          }
          if (!data.paidVouchers) {
            data.paidVouchers = 0;
          }
          return { ...data };
        })
      ),
      take(1)
    );
    this.organisations.subscribe((organisations) => {
      console.log('organisations', organisations);
      this.organisationsArray = organisations;
    });

    this.voucherGroupCollection = this.db.collection<VoucherGroup>(
      '/township/' + localStorage.getItem('township') + '/voucherGroups'
    );
    this.voucherGroupCollection.snapshotChanges().subscribe((result) => {
      // tslint:disable-next-line: no-shadowed-variable
      result.forEach((element: any) => {
        const data = element.payload.doc.data();
        data.id = element.payload.doc.id;
        this.voucherGroupArray.push(data);
      });
    });

    const combinedObservable = combineLatest(
      this.organisations,
      this.searchQuery
    );
    combinedObservable
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((values) => {
        console.log('values', values);
        const orgs = values[0];
        const searchQuery = values[1];
        // const filters = {
        //   showHidden: values[2],
        //   orderBy: values[3],
        //   orderDirection: values[4]
        // };
        const filteredOrgs = orgs.filter((item) =>
          this.checkFilters(searchQuery, item)
        );

        console.log('filteredOrgs', filteredOrgs);
        this.totalOrgsFound = filteredOrgs.length;

        this.filteredOrganisations.next(filteredOrgs);
        // this.filteredUsers.next(this.allUsers.pipe(map(items => items.filter(item => this.checkFilters(item)))));
      });
    this.searchQuery.next('');

    this.activateForm.controls['search'].valueChanges.subscribe((value) => {
      console.log('searchQueryChangedTo:', value);
      this.searchQuery.next(value);
    });
    this.claimForm.controls['search'].valueChanges.subscribe((value) => {
      console.log('searchQueryChangedTo:', value);
      this.searchQuery.next(value);
    });
    this.claimForm.controls['search'].valueChanges.subscribe((value) => {
      console.log('searchQueryChangedTo:', value);
      this.searchQuery.next(value);
    });

    this.claimForm.controls.couponNumber.valueChanges.pipe(debounceTime(20000)).subscribe((result) => {
      this.barcode = null;
    });
  }

  checkFilters(searchQuery, item) {
    let passesSearchFilter = true;
    if (searchQuery) {
      searchQuery = searchQuery.toLowerCase();
      passesSearchFilter = false;
      if (item.name) {
        if (item.name.toLowerCase().includes(searchQuery)) {
          passesSearchFilter = true;
        }
      }
      if (item.description) {
        if (item.description.toLowerCase().includes(searchQuery)) {
          passesSearchFilter = true;
        }
      }
    }

    if (passesSearchFilter) {
      return item;
    }
  }

  async findVoucher(voucherNumber: string) {
    if (this.saving) {
      return;
    }
    this.voucherNumber = voucherNumber;
    this.saving = true;
    const voucher = await this.getVoucher(voucherNumber);
    if (voucher) {
      this.voucher = voucher;
      this.voucherFound = true;
      this.voucherForm.patchValue(voucher);
      const voucherGroup = (
        await this.db
          .doc(`${this.townShipRef}voucherGroups/${voucher.voucherGroupId}`)
          .ref.get()
      ).data() as VoucherGroup;
      console.log('voucherGroup', voucherGroup);
      this.voucherForm.controls.voucherGroupId.setValue(voucherGroup.name);
      this.voucherForm.controls.voucherGroupId.disable();
    } else {
      this.showError('Deze bon bestaat niet.');
    }
    this.saving = false;
  }

  async getVoucher(voucherNumber: string) {
    this.voucherDoc = this.db.doc<Voucher>(
      'township/' +
      localStorage.getItem('township') +
      '/vouchers/' +
      voucherNumber
    );
    const voucher = (await this.voucherDoc.ref.get()).data() as any;
    if (voucher) {
      console.log('voucher', { ...voucher });
      const patchObj = this.voucher as any;
      if (voucher.activateDate) {
        voucher.activateDate = voucher.activateDate.toDate();
      }
      if (voucher.claimDate) {
        voucher.claimDate = voucher.claimDate.toDate();
      }
      if (voucher.paidDate) {
        voucher.paidDate = voucher.paidDate.toDate();
      }
    }
    return voucher;
  }

  async checkTags(voucher: Voucher) {
    let hasRights = false;
    const voucherGroupTagsCollection = this.db.collection<Tag>(
      '/township/' +
      localStorage.getItem('township') +
      '/voucherGroups/' +
      voucher.voucherGroupId +
      '/tags'
    );
    const voucherGroupTags = voucherGroupTagsCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as Tag;
          data.id = a.payload.doc.id;
          return { ...data };
        })
      ),
      take(1)
    );
    await voucherGroupTags.forEach((tags) => {
      console.log('voucherGroupTags', tags);
      if (tags.length === 0) {
        hasRights = true;
      } else {
      }
      tags.forEach((tag) => {
        if (this.entrepreneurTagsObj[tag.id]) {
          if (this.claim && this.entrepreneurTagsObj[tag.id].claimRights) {
            console.log('matching tag', this.entrepreneurTagsObj[tag.id]);
            hasRights = true;
          }
          if (
            this.activate &&
            this.entrepreneurTagsObj[tag.id].activateRights
          ) {
            console.log('matching tag', this.entrepreneurTagsObj[tag.id]);
            hasRights = true;
          }
        }
      });
    });
    return hasRights;
  }

  async checkValidity(voucher, voucherGroup: VoucherGroup) {
    let isValid = true;
    const now = new Date();
    console.log('now', now);
    if (voucherGroup.validUntilDate) {
      if (voucherGroup.validUntilDate.toDate() < now) {
        console.log(
          'voucherGroup.validUntilDate',
          voucherGroup.validUntilDate.toDate()
        );
        isValid = false;
      }
    }
    if (voucherGroup.validUntilTime && voucher.activateDate) {
      const activateDate = voucher.activateDate;
      const activateDateWithoutHours = new Date(
        activateDate.getFullYear(),
        activateDate.getMonth(),
        activateDate.getDate() + 1
      );
      let validDate;
      console.log('activateDate', activateDate);
      console.log('activateDateWithoutHours', activateDateWithoutHours);
      const validUntilTimeValue = voucherGroup.validUntilTimeValue as number;
      switch (voucherGroup.validUntilTimeType) {
        case 'days':
          validDate = new Date(
            activateDate.getFullYear(),
            activateDate.getMonth(),
            activateDate.getDate() + validUntilTimeValue
          );
          break;
        case 'weeks':
          validDate = new Date(
            activateDate.getFullYear(),
            activateDate.getMonth(),
            activateDate.getDate() + validUntilTimeValue * 7
          );
          break;
        case 'months':
          validDate = new Date(
            activateDate.getFullYear(),
            activateDate.getMonth() + validUntilTimeValue,
            activateDate.getDate()
          );
          break;
        case 'years':
          validDate = new Date(
            activateDate.getFullYear() + validUntilTimeValue,
            activateDate.getMonth(),
            activateDate.getDate()
          );
          break;
      }
      if (validDate < now) {
        console.log('voucherGroup.validDateTime', validDate);
        isValid = false;
      }
    }
    // Overwrites
    if (voucher.validUntilDate) {
      if (voucher.validUntilDate.toDate() < now) {
        console.log('voucher.validUntilDate', voucher.validUntilDate.toDate());
        isValid = false;
      } else {
        isValid = true;
      }
    }
    return isValid;
  }

  async save(form: FormGroup) {
    if (form.invalid || this.saving) {
      console.log('this.saving', form.invalid);
      return;
    }
    this.saving = true;
    this.errorMessage = '';
    const saveObj = { ...form.value };
    delete saveObj.search;
    delete saveObj.couponNumber;
    console.log('form.value', { ...saveObj });
    // const claimOrganisationId = this.voucherForm.value.claimOrganisationId;
    // const claimDate = this.voucherForm.value.claimDate;
    // const amountToPayOrg = this.voucherForm.value.amountToPayOrg;
    // const activateOrganisationId = this.voucherForm.value.claimOrganisationId;
    // const activateDate = this.voucherForm.value.activateDate;

    let voucher: Voucher;
    let voucherGroup: VoucherGroup;
    let checked = false;
    let snackbarValue;
    let hasRights = true;
    let isValid = true;

    if (!this.edit) {
      // check if voucher is valid before claiming
      voucher = await this.getVoucher(form.value.couponNumber);
      console.log('voucher', { ...voucher });
      if (!voucher) {
        this.showError('Deze bon bestaat niet.');
      }
      if (voucher) {
        if (voucher.voucherGroupId) {
          voucherGroup = (
            await this.db
              .doc(`${this.townShipRef}voucherGroups/${voucher.voucherGroupId}`)
              .ref.get()
          ).data() as VoucherGroup;
        }
        if (this.isEntrepreneur) {
          hasRights = await this.checkTags(voucher);
          isValid = await this.checkValidity(voucher, voucherGroup);
          if (this.activate) {
            saveObj.activateOrganisationId = this.entrepreneurId;
            saveObj.activateDate = new Date();
          }
          if (this.claim) {
            saveObj.claimOrganisationId = this.entrepreneurId;
            saveObj.claimDate = new Date();
          }
        }
        if (this.activate) {
          if (voucher.activateDate) {
            this.showError('Deze bon is al geactiveerd.');
          } else {
            console.log('checked activate');
            checked = true;
          }
          // saveObj.activateOrganisationId = activateOrganisationId;
          if (saveObj.activateOrganisationId !== 'none') {
            // tslint:disable-next-line: no-shadowed-variable
            this.organisationsArray.forEach((element: any) => {
              if (element.id === saveObj.activateOrganisationId) {
                saveObj.activateOrganisation = element.lowercaseName;
              }
            });
          } else {
            saveObj.activateOrganisation = firestore.FieldValue.delete();
            saveObj.activateOrganisationId = firestore.FieldValue.delete();
          }
          snackbarValue = form.value.value;
        } else if (this.claim) {
          if (!voucher.activateDate) {
            this.showError('Deze bon moet eerst geactiveerd worden.');
            this.barcode = null;
          } else if (voucher.claimDate) {
            this.showError('Deze bon is al geclaimd.');
            this.barcode = null;
          } else {
            console.log('checked claim');
            checked = true;
          }
          if (voucherGroup.claimInstantly) {
            // Claim voucher fully when claimInstantly is enabled.
            saveObj.receiptValue = voucher.value;
            saveObj.amountToPayOrg = voucher.value;
          } else if (
            saveObj.receiptValue &&
            saveObj.receiptValue.length !== 0
          ) {
            saveObj.amountToPayOrg =
              saveObj.receiptValue > voucher.value
                ? voucher.value
                : saveObj.receiptValue;
          } else {
            // Voucher has claimInstantly turned off so it requires a receiptValue
            this.showError('Voor deze bon moet je een aankoopwaarde invullen.');
            checked = false;
          }
          snackbarValue = saveObj.amountToPayOrg;
          if (saveObj.claimOrganisationId !== 'none') {
            // tslint:disable-next-line: no-shadowed-variable
            this.organisationsArray.forEach((element: any) => {
              if (element.id === saveObj.claimOrganisationId) {
                saveObj.claimOrganisation = element.lowercaseName;
              }
            });
          } else {
            saveObj.claimOrganisation = firestore.FieldValue.delete();
            saveObj.claimOrganisationId = firestore.FieldValue.delete();
          }
        }
      }
    } else {
      checked = true;
    }
    if (checked) {
      // Only move on to these errors if voucher is checked
      if (!isValid) {
        this.showError('Deze bon is niet meer geldig.');
        this.barcode = null;
      } else if (!hasRights) {
        if (this.claim) {
          this.showError('Je hebt geen rechten om deze bon te claimen.');
          this.barcode = null;
        }
        if (this.activate) {
          this.showError('Je hebt geen rechten om deze bon te activeren.');
          this.barcode = null;
        }
      }
    }
    if (checked && hasRights && isValid) {
      Object.keys(saveObj).forEach((key) => {
        if (saveObj[key] == null) {
          saveObj[key] = null;
        } else if (
          typeof saveObj[key] === 'string' &&
          saveObj[key].length === 0
        ) {
          saveObj[key] = null;
        }
      });

      if (saveObj.claimOrganisationId !== 'none') {
        // tslint:disable-next-line: no-shadowed-variable
        this.organisationsArray.forEach((element: any) => {
          if (element.id === saveObj.claimOrganisationId) {
            saveObj.claimOrganisationId = element.id;
            saveObj.claimOrganisation = element.lowercaseName;
          }
        });
      } else {
        saveObj.claimOrganisationId = null;
        saveObj.claimOrganisation = null;
      }

      console.log('saveObj', saveObj);
      // Save values to db :)
      await this.voucherDoc.set(saveObj, { merge: true });
      if (this.edit) {
        this.snackBar.open(`Bon ${this.voucherNumber} ${this.txt[2]}`, '', {
          duration: 4000,
          panelClass: ['snackbar'],
        });
        this.dialogRef.close();
      } else {
        this.succeedActions++;
        this.snackBar.open(
          `Bon ${form.value.couponNumber} ${this.txt[2]} voor ${snackbarValue} euro`,
          '',
          {
            duration: 4000,
            panelClass: ['snackbar'],
          }
        );
        form.controls.couponNumber.setValue('');
        this.ref.nativeElement.focus(); // focus to bon nummer input
        if (this.claim) {
          let barcodeFound = false;
          this.organisations.forEach((org) => {
            console.log('org', org);
            org.forEach((element: any) => {
              if (element.id === saveObj.claimOrganisationId) {
                if (element.discountCode) {
                  [...element.discountCode].forEach(discount => {
                    if (discount.voucherId === voucher.voucherGroupId) {
                      this.barcode = discount.barcode;
                      barcodeFound = true;
                    }
                  });
                }
              }
            });
          });
          if (!barcodeFound) {
            this.barcode = null;
          }
          // this.snackBar.openFromComponent(BarcodeComponent, {
          //   duration: 20 * 1000,
          //   data: this.barcode,
          // },
          // );
        }
      }
    }
    // console.log('saveObj.claimOrganisationId', saveObj.claimOrganisationId);
    // Make convert empty strings to 'null' for our queries.
    this.saving = false;
  }

  showError(message: string) {
    this.error = true;
    // form.controls.couponNumber.setValue(message);
    this.errorMessage = message;
    this.ref.nativeElement.focus();
    this.ref.nativeElement.select();
  }

  isFocus() {
    // console.log('is focus');
    if (this.claimForm.valid) {
      this.save(this.claimForm);
    } else if (this.activateForm.valid) {
      this.save(this.activateForm);
    } else if (this.numberForm.valid) {
      this.findVoucher(this.numberForm.value.number);
    }
  }
}
