import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public pageTitle: string;
  // title = 'Bob de bot';

  constructor(
    private titleService: Title,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.titleService.setTitle(environment.name);
    this.matIconRegistry.addSvgIcon(
      `upgrade_custom`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `../assets/icons/upgrade.svg`
      )
    );
  }
  ngOnInit(): void {}
}
