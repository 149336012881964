import {
  Component,
  OnInit,
  Inject,
  ElementRef,
  ViewChild,
} from '@angular/core';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { Organisation, OrganisationTag, Tag } from 'src/app/interfaces';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { firestore } from 'firebase';
import { MatAutocompleteSelectedEvent, MatSnackBar } from '@angular/material';
import { environment } from '../../../environments/environment';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, take } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

export interface DialogData {
  organisation: Organisation;
}

@Component({
  selector: 'app-edit-organisation',
  templateUrl: './edit-organisation.component.html',
  styleUrls: ['./edit-organisation.component.scss'],
})
export class EditOrganisationComponent implements OnInit {
  saving: boolean;
  newOrganisation = false;
  organisationId: string;
  organisationForm: FormGroup;
  townShipRef = '/township/' + localStorage.getItem('township') + '/';

  // Everything tags
  @ViewChild('tagInput', { static: false }) tagInput: ElementRef<
    HTMLInputElement
  >;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  activateTagCtrl = new FormControl();
  activateFilteredTags: ReplaySubject<Tag[]> = new ReplaySubject<Tag[]>(1);
  claimTagCtrl = new FormControl();
  claimFilteredTags: ReplaySubject<Tag[]> = new ReplaySubject<Tag[]>(1);
  allTags: Observable<Tag[]>;
  organisationTags: Observable<OrganisationTag[]>;
  private allTagsCollection: AngularFirestoreCollection<Tag>;
  private organisationTagsCollection: AngularFirestoreCollection<
    OrganisationTag
  >;
  selectedActivateTags = [];
  selectedClaimTags = [];
  public tagFilterCtrl: FormControl = new FormControl();
  public filteredTagsCtrl: ReplaySubject<Tag[]> = new ReplaySubject<Tag[]>(1);

  apiConnected: boolean;

  constructor(
    public db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditOrganisationComponent>,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.organisationForm = this.fb.group({
      name: [, Validators.required],
      ownerEmail: [, Validators.email],
      apiConnection: [],
      website: [],
    });
    if (this.data.organisation) {
      this.organisationId = this.data.organisation.id;
      console.log('this.data.organisation', this.data.organisation);
      if (this.data.organisation.apiConnection) {
        this.apiConnected = true;
      }
      this.organisationForm.patchValue(this.data.organisation);
    } else {
      this.organisationId = this.db.createId();
      this.newOrganisation = true;
      this.apiConnected = false;
    }
    this.organisationTagsCollection = this.db.collection<OrganisationTag>(
      this.townShipRef + 'organisations/' + this.organisationId + '/tags'
    );
    this.organisationTags = this.organisationTagsCollection
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as OrganisationTag;
            data.id = a.payload.doc.id;
            return data;
          })
        ),
        take(1)
      );
    this.organisationTags.subscribe((value) => {
      value.forEach((tag) => {
        if (tag.activateRights) {
          this.selectedActivateTags.push(tag);
        }
        if (tag.claimRights) {
          this.selectedClaimTags.push(tag);
        }
      });
      console.log('this.selectedActivateTags', this.selectedActivateTags);
      console.log('this.selectedClaimTags', this.selectedClaimTags);
    });
    this.allTagsCollection = this.db.collection<Tag>(`${this.townShipRef}tags`);
    this.allTags = this.allTagsCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as Tag;
          data.id = a.payload.doc.id;
          return data;
        })
      ),
      take(1)
    );
    const combinedActivateFilter = combineLatest(
      this.activateTagCtrl.valueChanges,
      this.allTags
    );
    combinedActivateFilter
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((values) => {
        console.log('values', values);
        const filteredTags = [];
        const input = values[0];
        const allTags = values[1];
        console.log('allTags', allTags);
        allTags.forEach((tag) => {
          const tagName = tag.name.toLowerCase();
          if (tagName.includes(input)) {
            filteredTags.push(tag);
          }
        });

        console.log('activateFilteredTags', filteredTags);
        this.activateFilteredTags.next(filteredTags);
        // this.filteredUsers.next(this.allUsers.pipe(map(items => items.filter(item => this.checkFilters(item)))));
      });
    this.activateTagCtrl.setValue('');

    const combinedClaimFilter = combineLatest(
      this.claimTagCtrl.valueChanges,
      this.allTags
    );
    combinedClaimFilter
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((values) => {
        console.log('values', values);
        const filteredTags = [];
        const input = values[0];
        const allTags = values[1];
        console.log('allTags', allTags);
        allTags.forEach((tag) => {
          const tagName = tag.name.toLowerCase();
          if (tagName.includes(input)) {
            filteredTags.push(tag);
          }
        });

        console.log('claimFilteredTags', filteredTags);
        this.claimFilteredTags.next(filteredTags);
        // this.filteredUsers.next(this.allUsers.pipe(map(items => items.filter(item => this.checkFilters(item)))));
      });
    this.claimTagCtrl.setValue('');
  }

  remove(tag: Tag, array: Tag[]): void {
    console.log(tag);
    const index = array.indexOf(tag);

    if (index >= 0) {
      array.splice(index, 1);
    }
  }

  async selected(
    event: MatAutocompleteSelectedEvent,
    array: Tag[],
    input: any
  ): Promise<void> {
    // this.selectedTags.push(event.option.viewValue);
    const value = event.option.value as Tag;
    console.log('value', value);

    const foundTag = await array.find((lfTag) => {
      if (lfTag.id === value.id) {
        return lfTag;
      }
    });
    if (!foundTag) {
      array.push(value);
      this.tagInput.nativeElement.value = '';
      input.setValue('');
    } else {
      this.tagInput.nativeElement.value = '';
      input.setValue('');
      this.snackBar.open('Dit label hangt al aan deze groep.', 'X', {
        duration: 5000,
      });
    }
  }

  async save() {
    if (this.saving) {
      return;
    }
    this.saving = true;
    const saveObj = { ...this.organisationForm.value } as Organisation;
    saveObj.lowercaseName = saveObj.name.toLowerCase();
    const tagBatch = this.db.firestore.batch();

    // if (!this.urlCheck(saveObj.website)) {
    //   return;
    // }
    if (saveObj.website) {
      if (!this.urlCheck(saveObj.website)) {
        this.organisationForm.controls['website'].setErrors({ incorrect: true });
        return;
      }
    }

    // Check tags for changes
    console.log('selectedActivateTags', this.selectedActivateTags);
    console.log('selectedClaimTags', this.selectedClaimTags);
    const tagsObj = {};
    await this.organisationTags.forEach(async (tags) => {
      tags.forEach(async (tag) => {
        if (tag.activateRights) {
          delete tag.activateRights;
        }
        if (tag.claimRights) {
          delete tag.claimRights;
        }
        tagsObj[tag.id] = tag;
        tagsObj[tag.id].delete = true;
      });
    });
    if (this.selectedActivateTags) {
      this.selectedActivateTags.forEach((tag) => {
        if (tag.claimRights) {
          delete tag.claimRights;
        }
        if (!tagsObj[tag.id]) {
          tagsObj[tag.id] = tag;
        }
        tagsObj[tag.id].activateRights = true;
        tagsObj[tag.id].delete = false;
      });
    }
    if (this.selectedClaimTags) {
      this.selectedClaimTags.forEach((tag) => {
        if (tag.activateRights) {
          delete tag.activateRights;
        }
        if (!tagsObj[tag.id]) {
          tagsObj[tag.id] = tag;
        }
        tagsObj[tag.id].claimRights = true;
        tagsObj[tag.id].delete = false;
      });
    }
    Object.keys(tagsObj).forEach((tagId) => {
      console.log('tagId', tagId);
      const orgTagRef = this.db.doc(
        `${this.townShipRef}organisations/${this.organisationId}/tags/${tagId}`
      ).ref;
      const tagOrgRef = this.db.doc(
        `${this.townShipRef}tags/${tagId}/organisations/${this.organisationId}`
      ).ref;
      const tagOrgObj = {
        id: this.organisationId,
        name: saveObj.name,
      };
      if (tagsObj[tagId].activateRights) {
        tagOrgObj['activateRights'] = true;
      }
      if (tagsObj[tagId].claimRights) {
        tagOrgObj['claimRights'] = true;
      }
      if (tagsObj[tagId].delete) {
        tagBatch.delete(orgTagRef);
        tagBatch.delete(tagOrgRef);
      } else {
        delete tagsObj[tagId].delete;
        tagBatch.set(orgTagRef, tagsObj[tagId]);
        tagBatch.set(tagOrgRef, tagOrgObj);
      }
      // }
    });

    // Make sure no "null" values are attempting to be saved, also if something is null it will be deleted from the database.
    Object.keys(saveObj).forEach((key) => {
      if (saveObj[key] == null) {
        saveObj[key] = firestore.FieldValue.delete();
      } else if (
        typeof saveObj[key] === 'string' &&
        saveObj[key].length === 0
      ) {
        saveObj[key] = firestore.FieldValue.delete();
      }
    });
    console.log('saveObj', saveObj);
    console.log('tagsObj', tagsObj);
    console.log('tagBatch', tagBatch);
    // Save values to tb :)
    await this.db
      .collection(this.townShipRef + 'organisations')
      .doc(this.organisationId)
      .set(saveObj, { merge: true });
    await tagBatch.commit();
    this.dialogRef.close();
  }

  getError(name) {
    const field = this.organisationForm.get(name);
    if (field.touched || !field.pristine) {
      if (field.hasError('required')) {
        return 'Dit veld moet ingevuld zijn.';
      }
      return '';
    }
  }

  urlCheck(saveObj) {
    let invalidUrl = false;

    const expression = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    const regex = new RegExp(expression);

    if (saveObj.match(regex)) {
      console.log('valid URL', saveObj);
    } else {
      invalidUrl = true;
    }

    if (invalidUrl) {
      this.organisationForm.controls.website.setErrors({
        notMatched: true
      });
      this.saving = false;
      return false;
    } else {
      return true;
    }
  }
}
