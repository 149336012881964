import { Component, OnInit } from '@angular/core';
import {
  AngularFirestoreCollection,
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { VoucherGroup, Voucher, Township } from '../interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { VoucherGroupEditComponent } from './voucher-group-edit/voucher-group-edit.component';
import { MatDialog, MatSnackBar } from '@angular/material';
import { VoucherGroupDetailComponent } from './voucher-group-detail/voucher-group-detail.component';
import * as XLSX from 'xlsx';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { FuncsService } from 'src/services/funcs.services';

@Component({
  selector: 'app-voucher-management',
  templateUrl: './voucher-management.component.html',
  styleUrls: ['./voucher-management.component.scss'],
})
export class VoucherManagementComponent implements OnInit {
  env = environment;
  voucherGroupCollection: AngularFirestoreCollection<VoucherGroup>;
  voucherGroups: Observable<VoucherGroup[]>;
  townshipDoc: AngularFirestoreDocument<Township>;
  $township: Observable<Township>;
  township: Township;
  public chartScheme = { domain: ['#62C590', '#F5DC69', '#FC9F5B', '#1A697F'] };

  constructor(
    public db: AngularFirestore,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private http: HttpClient,
    private funcs: FuncsService
  ) {}

  ngOnInit() {
    this.funcs.showUpgradeDialogIfOverLimit();
    this.townshipDoc = this.db.doc<Township>(
      `township/${localStorage.getItem('township')}`
    );
    this.$township = this.townshipDoc.valueChanges();
    this.$township.subscribe((value) => {
      this.township = value;
      console.log('township', this.township);
    });
    this.voucherGroupCollection = this.db.collection(
      '/township/' + localStorage.getItem('township') + '/voucherGroups',
      (ref) => ref.orderBy('name')
    );
    this.voucherGroups = this.voucherGroupCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as VoucherGroup;
          data.id = a.payload.doc.id;

          data.totalVouchers = data.totalVouchers ? data.totalVouchers : 0;
          data.paidVouchers = data.paidVouchers ? data.paidVouchers : 0;
          data.claimedVouchers = data.claimedVouchers
            ? data.claimedVouchers
            : 0;
          data.activatedVouchers = data.activatedVouchers
            ? data.activatedVouchers
            : 0;
          // data.maxVouchers = data.maxVouchers ? data.maxVouchers : 0;
          let paidVouchers = data.paidVouchers;
          let claimedVouchers = data.claimedVouchers;
          let activatedVouchers = data.activatedVouchers;

          // if (data.maxVouchers && data.maxVouchers < data.totalVouchers) { data.totalVouchers = data.maxVouchers; }
          data.totalVouchers =
            data.maxVouchers && data.maxVouchers < data.totalVouchers
              ? data.maxVouchers
              : data.totalVouchers;
          const availableVouchers = data.totalVouchers - data.activatedVouchers;
          let voucherCountDown = data.totalVouchers;

          if (paidVouchers > voucherCountDown) {
            paidVouchers = voucherCountDown;
            voucherCountDown = 0;
          } else {
            voucherCountDown = voucherCountDown - paidVouchers;
          }
          if (claimedVouchers > voucherCountDown) {
            claimedVouchers = voucherCountDown;
            voucherCountDown = 0;
          } else {
            claimedVouchers = claimedVouchers - data.paidVouchers;
            voucherCountDown = voucherCountDown - claimedVouchers;
          }
          if (activatedVouchers > voucherCountDown) {
            activatedVouchers = voucherCountDown;
            voucherCountDown = 0;
          } else {
            activatedVouchers = activatedVouchers - data.claimedVouchers;
            voucherCountDown = voucherCountDown - activatedVouchers;
          }

          data.statisticsChart = [
            {
              name: 'Beschikbaar',
              value: availableVouchers,
            },
            {
              name: 'Geactiveerd',
              value: activatedVouchers,
            },
            {
              name: 'Geclaimd',
              value: claimedVouchers,
            },
            {
              name: 'Betaald',
              value: paidVouchers,
            },
          ];
          return { ...data };
        })
      )
    );
    this.voucherGroups.subscribe((voucherGroups) => {
      console.log('voucherGroups', voucherGroups);
    });
  }

  openVoucherGroupDetails(voucherGroup) {
    const dialogRef = this.dialog.open(VoucherGroupDetailComponent, {
      width: '373px',
      data: { voucherGroup },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      this.funcs.showUpgradeDialogIfOverLimit();
    });
  }
  openEditVoucherGroup(voucherGroup?) {
    const dialogRef = this.dialog.open(VoucherGroupEditComponent, {
      width: '500px',
      data: { voucherGroup, township: this.township },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      this.funcs.showUpgradeDialogIfOverLimit();
    });
  }

  copyIframeUrl(voucherGroup) {
    const el = document.createElement('textarea');
    if (this.env.production) {
      el.value = `https://frame.groenebon.nl/activate/${localStorage.getItem(
        'township'
      )}/${voucherGroup.id}`;
    } else {
      el.value = `https://dev-frame.groenebon.nl/activate/${localStorage.getItem(
        'township'
      )}/${voucherGroup.id}`;
    }
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this.snackBar.open('Link gekopieerd.', 'X', {
      duration: 3000,
    });
  }
  async recalcStats(voucherGroup) {
    const requestUrl = `${environment.functionsUrl}/httpRecalcStatistics`;
    this.http
      .post(requestUrl, {
        type: 'voucherGroup',
        townshipId: localStorage.getItem('township'),
        voucherGroupId: voucherGroup.id,
      })
      .subscribe((res) => {
        console.log(res);
      });
    this.snackBar.open(
      'Statistieken worden herberekend, even geduld a.u.b.',
      'X',
      {
        duration: 5000,
      }
    );
  }

  async exportVoucherGroup(voucherGroup) {
    console.log('voucherGroup', voucherGroup);
    const voucherRef = this.db.collection(
      '/township/' + localStorage.getItem('township') + '/vouchers/',
      (ref) =>
        ref.where('voucherGroupId', '==', voucherGroup.id).orderBy('number')
    );
    const vouchersArray = [];
    const vouchersObservable = await voucherRef.get();
    await vouchersObservable.forEach((vouchers) => {
      // console.log('vouchers', vouchers);
      vouchers.forEach((voucherDoc) => {
        const voucher = voucherDoc.data() as Voucher;
        const exportVoucherObj = {};
        exportVoucherObj['Vouchernummer'] = voucher.number
          ? voucher.number
          : '';
        exportVoucherObj['Waarde'] =
          voucher.value !== null ? Number(voucher.value) : '';
        exportVoucherObj['Te betalen waarde'] = voucher.amountToPayOrg
          ? Number(voucher.amountToPayOrg)
          : '';
        exportVoucherObj['E-mail'] = voucher.email ? voucher.email : '';
        exportVoucherObj['Postcode'] = voucher.postal ? voucher.postal : '';
        exportVoucherObj['Image Url'] = voucher.imageUrl
          ? voucher.imageUrl
          : '';
        exportVoucherObj['Huisnummer'] = voucher.houseNumber
          ? voucher.houseNumber
          : '';
        exportVoucherObj['Toevoeging'] = voucher.houseNumberAddition
          ? voucher.houseNumberAddition
          : '';
        exportVoucherObj['Activatiedatum'] = voucher.activateDate
          ? voucher.activateDate.toDate().getDate() +
            '-' +
            (voucher.activateDate.toDate().getMonth() + 1) +
            '-' +
            voucher.activateDate.toDate().getFullYear()
          : '';
        exportVoucherObj['Claimbedrijf'] = voucher.claimOrganisation
          ? voucher.claimOrganisation
          : '';
        exportVoucherObj['Claimdatum'] = voucher.claimDate
          ? voucher.claimDate.toDate().getDate() +
            '-' +
            (voucher.claimDate.toDate().getMonth() + 1) +
            '-' +
            voucher.claimDate.toDate().getFullYear()
          : '';
        exportVoucherObj['Betaaldatum'] = voucher.paidDate
          ? voucher.paidDate.toDate().getDate() +
            '-' +
            (voucher.paidDate.toDate().getMonth() + 1) +
            '-' +
            voucher.paidDate.toDate().getFullYear()
          : '';
        exportVoucherObj['Vervaldatum'] = voucher.validUntilDate
          ? voucher.validUntilDate.toDate().getDate() +
            '-' +
            (voucher.validUntilDate.toDate().getMonth() + 1) +
            '-' +
            voucher.validUntilDate.toDate().getFullYear()
          : '';
        exportVoucherObj['Herinnering verstuurd'] = voucher.reminderSend
          ? voucher.reminderSend
          : '';
        vouchersArray.push(exportVoucherObj);
      });
    });
    if (vouchersArray.length > 0) {
      console.log('vouchers', vouchersArray);
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(vouchersArray); // converts a DOM TABLE element to a worksheet
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Vouchers');

      // /* save to file */
      XLSX.writeFile(wb, this.env.name + '.' + voucherGroup.name + '.xlsx');
    } else {
      // Nothing to export
      this.snackBar.open('Er zijn geen bonnen om te exporteren.', 'X', {
        duration: 5000,
      });
    }
  }
}
